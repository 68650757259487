import React from 'react';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import { RadioGroup, RadioGroupProps, Stack } from '@mui/material';
import { KyronFormFieldLabel } from '../KyronFormFieldLabel';

// Add more ControllerProps in this list to have direct access to them in the HookSelect props
// rather than inside the otherControllerProps prop
type PickedControllerProps = 'name' | 'control' | 'rules';

export type HookRadioGroupProps<T extends FieldValues = FieldValues> = {
  controllerProps?: Omit<ControllerProps<T>, PickedControllerProps | 'render'>;
  topLabel?: string;
  topDescription?: string;
} & RadioGroupProps &
  Pick<ControllerProps<T>, PickedControllerProps>;

/**
 *  This is a RadioGroup component from MUI v5 wrapped in a Controller component from react-hook-form
 *  Ready to use with useForm hook!
 *  example usage:
 *  ```jsx
 *  const { control } = useForm();
 *
 *  <HookRadioGroup
 *    name='videoIntroduction'
 *    control={control}
 *    rules={{ required: 'This field is required' }}
 *    controllerProps={{ defaultValue: 'yes' }}
 *    id='video-introduction'
 *  >
 * ```
 */
export function HookRadioGroup<T extends FieldValues>({
  name,
  control,
  rules,
  controllerProps,
  topLabel,
  topDescription,
  ...radioGroupProps
}: HookRadioGroupProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      {...controllerProps}
      render={({ field: { onChange, value } }) => (
        <Stack>
          <KyronFormFieldLabel
            label={topLabel}
            description={topDescription}
            htmlFor={radioGroupProps.id}
            required={Boolean(rules?.required)}
          />
          <RadioGroup {...radioGroupProps} value={value} onChange={onChange}>
            {radioGroupProps.children}
          </RadioGroup>
        </Stack>
      )}
    />
  );
}
