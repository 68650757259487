import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { KyronID, Lesson, LessonCreationStage, LessonSection } from 'controllers/types';
import { useDeleteSection, useLessonSectionsQuery, useUpdateLessonAndLessonCollection } from 'controllers/react-query';
import { useUpdateAllSections } from 'controllers/react-query/lessonSectionHooks';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { SortableList } from 'components/SortableList';
import { Row } from 'components/Row/Row';
import { getProgressAndFailureFlags, useLessonBTWatcher } from 'components/BackgroundTasks/backgroundTaskWatchers';
import { Footer } from '../../../Components/MultiStepForm';
import { useMultiStepFormContext } from '../../../Components/MultiStepForm/context';
import { CourseModuleCard } from '../../../Components/CourseModuleCard';
import { FormContentPlaceholder } from '../../HelperComponents/FormContentPlaceholder';
import { useHandleCollapse } from './helpers/useHandleCollapse';
import { CategorizedTasks } from '../../../../../../channels/UserNotifications';

type CourseModulesFormProps = {
  lesson?: Lesson;
};
export function CourseModulesForm({ lesson }: CourseModulesFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { lessonId } = useMultiStepFormContext();

  const { data, isLoading, isFetching, isRefetching, isError } = useLessonSectionsQuery(lessonId);
  const { active, failed, ...btStatus } = useLessonBTWatcher(lesson?.id);

  const { mutateAsync, isPending: isDeletePending } = useDeleteSection();
  const { mutateAsync: updateModules } = useUpdateAllSections();
  const { mutateAsync: updateLessonAndCollection, isPending: isUpdatePending } = useUpdateLessonAndLessonCollection({
    lessonId: lesson?.id,
    collectionId: lesson?.lesson_collection?.id,
  });

  // ANTI-PATTERN: Storing data in local state. Here is why:
  // To retain a smoother UI experience with sortable lists, we need to keep data in UI state rather than using
  // react-query's data directly. This way we can do optimistic updates to the UI state when module order is changed
  // and prevent unnecessary re-renders, and unexpected sortable list behavior.
  const [lessonModules, setLessonModules] = useState<LessonSection[]>([]);

  // To retain a smoother UI experience with sortable lists, we need to keep data in UI state
  useEffect(updateUIStateWithServerState, [data?.lesson_sections]);
  function updateUIStateWithServerState() {
    if (data?.lesson_sections) {
      setLessonModules(data.lesson_sections);
    }
  }

  const areTherePendingActions = isUpdatePending || isDeletePending || btStatus.isModuleGenerationInProgress;

  const deleteModule = (moduleId: KyronID) => {
    if (!lessonId) return;
    mutateAsync({ lessonSectionId: moduleId, lessonId }).catch(() => {
      enqueueSnackbar('Failed to delete the module.', { variant: 'error' });
    });
  };

  const { collapseAll, allCollapsed, expandAll, allExpanded, collapsedModules } = useHandleCollapse(
    data?.lesson_sections || [],
  );

  // Update module positions will update the UI state optimistically first. Then, depending on the server's response,
  // it will either keep the optimistic update or revert back to the previous state.
  const updateModulePositions = (repositionedList: LessonSection[]) => {
    if (!lessonId) throw new Error('Lesson ID is missing');

    // update the value of each section according to new array index
    const updated: LessonSection[] = repositionedList.map((section, index) => ({ ...section, position: index + 1 }));

    const previousState = lessonModules;
    // optimistic update here - data will be reset to previous state if the mutation fails
    setLessonModules(updated);

    updateModules({ lessonId, payload: { lessonSections: updated } }).catch(() => {
      setLessonModules(previousState);
      enqueueSnackbar('Failed to reposition sections.', { variant: 'error' });
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant='headlineLarge'>Course Modules</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        This is a module outline of your course. Feel free to move, add or remove modules. You can edit the estimated
        duration and objectives for each module before we generate the individual module activities in the next step.
        Pro-top: It might be easier to reorder modules or get a quick overview if you collapse all the module cards
        below.
      </Typography>

      <Row gap={2} justifyContent='space-between' width='100%'>
        <Box>{isRefetching ? <LoadingIndicator message='Updating the data...' textVariant='bodyMedium' /> : null}</Box>
        <Row gap={2} justifyContent='flex-end' justifySelf='flex-end'>
          <Button
            size='small'
            variant='outlined'
            onClick={collapseAll}
            startIcon={<UnfoldLess />}
            disabled={allCollapsed || isFetching}
          >
            Collapse All
          </Button>
          <Button
            size='small'
            variant='outlined'
            onClick={expandAll}
            startIcon={<UnfoldMore />}
            disabled={allExpanded || isFetching}
          >
            Expand All
          </Button>
        </Row>
      </Row>

      <Box mt={2}>{getStatusMessage(btStatus, failed, active, lessonModules, isLoading, isError)}</Box>

      <SortableList
        items={lessonModules}
        onChange={updateModulePositions}
        renderItem={(module, opts) => (
          <SortableList.Item id={module.id}>
            <CourseModuleCard
              position={module.position}
              title={module.topic}
              objectives={module.structured_learning_objectives?.[0] || ''}
              collapsed={collapsedModules.includes(module.id)}
              onDeleteModule={() => deleteModule(module.id)}
              disableActions={areTherePendingActions}
              isOverlayItem={opts?.isOverlay}
            />
          </SortableList.Item>
        )}
      />

      <Footer
        onNext={async () => {
          await updateLessonAndCollection({
            ...lesson,
            creation_stage: LessonCreationStage.module_outline,
            lesson_collection: {},
          });
          navigate(`/studio/courses/${lesson?.id}`);
        }}
        isPending={areTherePendingActions}
        disableNextButton={isError || isFetching || isLoading || lessonModules.length === 0}
      />
    </Box>
  );
}

export function getStatusMessage(
  btStatus: ReturnType<typeof getProgressAndFailureFlags>,
  failed: CategorizedTasks,
  active: CategorizedTasks,
  lessonModules: LessonSection[],
  isLoading: boolean,
  isError: boolean,
) {
  if (isLoading) {
    return <FormContentPlaceholder variant='progress' message='Loading modules...' />;
  }

  if (isError) {
    return <FormContentPlaceholder variant='error' message='Could not load the modules.' />;
  }

  if (btStatus.isModuleGenerationInProgress) {
    return <FormContentPlaceholder variant='progress' message={active.moduleGeneration[0].message} />;
  }

  if (btStatus.isModuleGenerationFailed) {
    return <FormContentPlaceholder variant='error' message={failed.moduleGeneration[0].message} />;
  }

  if (!lessonModules.length) {
    return <FormContentPlaceholder variant='info' message='No modules found.' />;
  }

  return null;
}
