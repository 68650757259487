import { BackgroundTask } from '../../controllers/types';

export type CategorizedTasks = {
  moduleGeneration: BackgroundTask[];
  lessonPlanning: BackgroundTask[];
  lessonPublishing: BackgroundTask[];
  lessonTranslation: BackgroundTask[];
  videoGeneration: BackgroundTask[];
  videoRegeneration: BackgroundTask[];
  all: BackgroundTask[];
};

export const emptyCategorizedTasks: CategorizedTasks = {
  moduleGeneration: [],
  lessonPlanning: [],
  lessonPublishing: [],
  lessonTranslation: [],
  videoGeneration: [],
  videoRegeneration: [],
  all: [],
};

export const initialBackgroundTasks = {
  active: emptyCategorizedTasks,
  failed: emptyCategorizedTasks,
};

export type UserNotifications = {
  backgroundTasks: {
    active: CategorizedTasks;
    failed: CategorizedTasks;
    done?: CategorizedTasks;
  };
};
