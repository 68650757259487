import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { LibraryLayout } from '../../Studio/Library/LibraryLayout';
import { Library } from '../../Studio';
import { Playlists } from '../../Studio/Library/Playlists';

export const libraryRoutes: RouteObject[] = [
  {
    path: 'library',
    element: <Navigate to='/studio/courses' replace />,
  },
  {
    element: <LibraryLayout />,
    children: [
      {
        path: 'courses',
        element: <Library />,
      },
      {
        path: 'playlists',
        element: <Playlists />,
      },
    ],
  },
];
