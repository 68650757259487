import { Box, Button, CircularProgress } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import React, { useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import { FormState, useFormContext } from 'react-hook-form';
import { Row } from '../../../../Row/Row';
import { useMultiStepFormContext } from './context';
import { FormValues } from '../../Forms/CourseBuilder/formValues';

const HEIGHT = 64;
const FooterShadow = styled(Box)({ height: HEIGHT });
const FormFooterPaper = styled(Row)(({ theme }) => ({
  justifyContent: 'center',
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: theme.palette.background.default,
  height: HEIGHT,
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const progressIcon = <CircularProgress size='1em' sx={{ color: 'text.disabled' }} data-testid='progress-indicator' />;

type TOnNext = (
  // takes multistep form component to the next form step
  goToNextStep: () => void,
  formState: FormState<FormValues>,
) => void | Promise<void>;

type TOnBack = (
  // takes multistep form component to the previous form step
  goToPreviousStep: () => void,
  formState: FormState<FormValues>,
) => void | Promise<void>;

type Props = {
  // Callback that will be called when next button is clicked. If returns a Promise, button will show progress until it resolves.
  onNext?: TOnNext;
  // Callback that will be called when back button is clicked. If returns a Promise, button will show progress until it resolves.
  onBack?: TOnBack;
  backButtonLabel?: string;
  nextButtonLabel?: string;
  // Disables both back and next buttons with progress indicator
  isPending?: boolean;
  disableNextButton?: boolean;
};

export function Footer({ backButtonLabel, nextButtonLabel, onNext, onBack, isPending, disableNextButton }: Props) {
  const { trigger, formState, reset, getValues } = useFormContext<FormValues>();
  const { activeStep, goToNextStep, goToPreviousStep, config } = useMultiStepFormContext();
  const isOnFirstStep = activeStep === 0;
  const [actionInProgress, setActionInProgress] = useState<boolean>(false);
  const isActionInProgress = isPending || actionInProgress;

  // Resets form's dirty state with new values
  const resetFormsDirtyState = useCallback(() => reset(getValues()), [reset, getValues]);

  const handleNext = useCallback(async () => {
    if (isPending) return;
    if (!(await trigger())) return; // validate form values
    setActionInProgress(true); // start progress indicator for async onNext action
    if (onNext) {
      // if there is a "next action" defined on form level, run that and pass the handler function to advance the form
      Promise.resolve(onNext(goToNextStep, formState))
        // here we are clearing the form's dirty state to be false with the new values
        // if this was not done, formState.isDirty would keep showing true for the submitted values
        .then(resetFormsDirtyState)
        .finally(() => {
          // stop progress indicator on buttons
          setActionInProgress(false);
        });
    } else {
      goToNextStep(); // default multistep form action
    }
  }, [resetFormsDirtyState, formState, trigger, isPending, onNext, goToNextStep]);

  const handleBack = useCallback(() => {
    if (isPending) return;
    if (onBack) {
      // if there is an action on request to go previous at form level, run that and pass the handler function to
      // take the form a step back
      onBack(goToPreviousStep, formState);
    } else {
      goToPreviousStep(); // default multistep form action
    }
  }, [formState, isPending, onBack, goToPreviousStep]);

  return (
    <>
      {/*
        FooterShadow will add empty space at the end of the main content to reserve footer's space in the DOM so that
        the FormFooterPaper (position: fixed) would not overlap the main content. If you take out the FooterShadow,
        when the main content is overflowing the viewport height, you will realize that the view will not scroll down
        as far as you would like it to.
      */}
      <FooterShadow />
      <FormFooterPaper>
        <Row width={config.MAX_WIDTH} justifyContent='space-between'>
          <Button
            startIcon={isActionInProgress ? progressIcon : <ArrowBack />}
            onClick={handleBack}
            variant='outlined'
            sx={{ opacity: isOnFirstStep ? 0 : 1 }} // hide on the first step
            disabled={isActionInProgress || isOnFirstStep}
            data-testid='back-button'
          >
            {backButtonLabel || 'Back'}
          </Button>
          <Button
            endIcon={isActionInProgress ? progressIcon : <ArrowForward />}
            variant='outlined'
            onClick={handleNext}
            disabled={isActionInProgress || disableNextButton}
            data-testid='next-button'
          >
            {nextButtonLabel || 'Next'}
          </Button>
        </Row>
      </FormFooterPaper>
    </>
  );
}
