import React, { useEffect, useState, useRef } from 'react';
import { Alert, Box, ListItemIcon, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import { MoreVert, DeleteOutlined } from '@mui/icons-material';
import { useDeleteSegment, useSectionQuery, useSegmentQuery, useUpdateSegment } from 'controllers/react-query';
import { SegmentMedia } from 'controllers/types';
import { Row } from 'components/Row/Row';
import { useSnackbar } from 'notistack';
import { useModal } from 'components/utils/ModalContext';
import { KyronEvents } from 'components/utils/KyronEvents';
import { useUserContext } from 'components/UserContext';
import { SEGMENT_TYPE_LABEL } from 'enums/segmentTypeLabel';
import { KyronMenu } from '../../KyronMenu';
import { SegmentMediaSelection } from '../components/SegmentMediaSelection';
import { StepSegmentFields, WaitSegmentFields } from './SegmentFields';
import { SegmentDetailSkeleton } from './EditorSkeleton';
import { SegmentMediaDisplay } from './SegmentMediaDisplay';
import { useEditorContext } from './EditorContext';
import { useLessonSegmentBTWatcher } from '../../BackgroundTasks/backgroundTaskWatchers';

export function SegmentDetail() {
  const { editorState, goToFirstSegmentOfActiveSection } = useEditorContext();
  const { data: currentSegment, error } = useSegmentQuery(editorState.activeSegmentId);
  const { data: currentSection } = useSectionQuery(editorState.activeSectionId);
  const segmentType = currentSegment?.segment_type;
  const { mutateAsync: deleteSegment } = useDeleteSegment();
  const { isPending } = useUpdateSegment({ segmentId: editorState.activeSegmentId });

  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const isWaitSegment = segmentType === 'wait';
  const isStepSegment = segmentType === 'step' || segmentType === 'question';
  const { user } = useUserContext();
  const titleRef = useRef<HTMLHeadingElement>(null);

  const { isAnyInProgress } = useLessonSegmentBTWatcher(editorState.activeSegmentId);

  useEffect(() => {
    // Scroll to the top of the page or top of the segment detail on first render
    if (window.scrollY > 200) {
      titleRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scroll(0, 0);
    }
  }, []);

  const [selectedMediaType, setSelectedMediaType] = useState<SegmentMedia>(
    currentSegment?.media_type || SegmentMedia.KYRON_VIDEO,
  );
  const synchronizeMediaType = () => {
    if (currentSegment?.media_type) {
      setSelectedMediaType(currentSegment.media_type);
    }
  };
  useEffect(synchronizeMediaType, [currentSegment?.id, currentSegment?.media_type]);

  function openDeleteModal() {
    if (!currentSegment) return;
    const handleDelete = () =>
      deleteSegment({
        segmentId: currentSegment.id,
        lessonId: currentSegment.lesson_id,
      })
        .then(() => {
          KyronEvents.sendEvent(KyronEvents.names.DELETE_SEGMENT, {
            segment_id: currentSegment.id,
            lesson_id: currentSegment.lesson_id,
            user_id: user?.id || 'anonymous',
          });
          goToFirstSegmentOfActiveSection();
        })
        .catch(deleteError => {
          const errorTitle = `Failed to delete segment`;
          console.error(errorTitle, currentSegment.id, deleteError.message);
          enqueueSnackbar(errorTitle, { variant: 'error' });
        });

    openModal({
      id: 'delete-segment',
      title: 'Delete Segment',
      content: 'Are you sure you want to delete this segment?',
      onConfirm: handleDelete,
    });
  }

  const editSegmentMenu = (
    <Box sx={{ position: 'absolute', left: '-44px', top: '14px' }} data-testid='EditSegmentMenu'>
      <KyronMenu menuIcon={<MoreVert />}>
        <MenuItem onClick={() => openDeleteModal()} sx={{ color: 'red' }} disabled={isAnyInProgress}>
          <ListItemIcon>
            <DeleteOutlined />
          </ListItemIcon>
          <ListItemText>Delete Segment</ListItemText>
        </MenuItem>
      </KyronMenu>
    </Box>
  );

  return (
    <Stack gap={3} sx={{ alignItems: 'stretch', flex: 1, scrollMarginTop: '64px' }} ref={titleRef}>
      {/* Error Display */}
      {error ? <Alert severity='error'>{error.message}</Alert> : null}
      {/* Segment Editor */}
      {currentSegment ? (
        <>
          <Box position='relative'>
            {/* DeleteSegment is disabled if there is only one segment in the section to avoid empty sections. Hiding the menu in that case as DeleteSegment is currently the only menu item */}
            {currentSection?.segments?.length && currentSection.segments.length > 1 && editSegmentMenu}
            <Stack>
              <Typography variant='labelSmall'>{currentSection?.topic}</Typography>
              <Typography variant='headlineMedium'>{SEGMENT_TYPE_LABEL[currentSegment.label]}</Typography>
            </Stack>
          </Box>

          <Row
            direction={{ xs: 'column', md: 'row' }}
            gap={{ xs: 4, md: 2 }}
            sx={{ alignItems: { xs: 'stretch', md: 'flex-start' } }}
          >
            {/* Media Display */}
            <SegmentMediaDisplay
              isPending={isAnyInProgress || isPending}
              segment={currentSegment}
              selectedMediaType={selectedMediaType}
            />

            {/* Media Type */}
            <Box sx={{ flex: '0 0 200px', width: '200px' }}>
              <SegmentMediaSelection
                key={currentSegment.id}
                segment={currentSegment}
                selectedMediaType={selectedMediaType}
                setSelectedMediaType={setSelectedMediaType}
                columnLayout
                isLoading={isAnyInProgress || isPending}
              />
            </Box>
          </Row>

          <Row direction={{ xs: 'column', md: 'row' }} gap={{ xs: 4, md: 2 }} sx={{ alignItems: 'stretch' }}>
            {/* Segment Fields */}
            <Box sx={{ flex: '1' }}>
              {isStepSegment && (
                <StepSegmentFields
                  segment={currentSegment}
                  isLoading={isAnyInProgress}
                  selectedMediaType={selectedMediaType}
                />
              )}
              {isWaitSegment && (
                <WaitSegmentFields
                  segment={currentSegment}
                  isLoading={isAnyInProgress}
                  selectedMediaType={selectedMediaType}
                />
              )}
            </Box>
            {/* Empty box for future segment options */}
            <Box sx={{ flex: '0 0 200px' }} />
          </Row>
        </>
      ) : (
        <SegmentDetailSkeleton />
      )}
    </Stack>
  );
}
