import { KyronID, LessonSection } from '../../../../../../../controllers/types';
import { useMultiStepFormContext } from '../../../../Components/MultiStepForm/context';

export function useHandleCollapse(modules: LessonSection[]) {
  const { contextState, setContextState } = useMultiStepFormContext();

  const collapseAll = () => setContextState({ collapsedModules: modules.map(module => module.id) });
  const allCollapsed = modules.length === contextState.collapsedModules.length;
  const expandAll = () => setContextState({ collapsedModules: [] });
  const allExpanded = contextState.collapsedModules.length === 0;
  const toggleCollapsed = (id: KyronID) => {
    setContextState(state => {
      const collapsedItems = state.collapsedModules;
      let updatedCollapsedItems: KyronID[];

      // if expanded, collapse
      if (collapsedItems.includes(id)) {
        updatedCollapsedItems = collapsedItems.filter(moduleId => moduleId !== id);
      } else {
        // if collapsed, expand
        updatedCollapsedItems = [...collapsedItems, id];
      }

      return { collapsedModules: updatedCollapsedItems };
    });
  };

  return {
    expandAll,
    toggleCollapsed,
    collapseAll,
    allCollapsed,
    allExpanded,
    collapsedModules: contextState.collapsedModules,
  };
}
