import React, { useState } from 'react';
import { Typography, Box, Drawer, TextField, Chip } from '@mui/material';
import { IRowNode } from 'ag-grid-community';
import { Warning, Search } from '@mui/icons-material';
import { StudentWork, Misconception } from 'controllers/types';
import dayjs from 'dayjs';
import { DataGrid } from '../common/DataGrid';
import { StudentPanel } from './StudentPanel';
import { Row } from '../Row/Row';

const styles = {
  title: {
    marginBottom: '10px',
    marginTop: '30px',
  },
  studentProgressReport: {
    height: '75vh',
    marginTop: '10px',
  },
  currentSection: {
    backgroundColor: 'primaryContainer.main',
    padding: '10px',
  },
  notCurrentSection: {
    backgroundColor: 'transparent',
    padding: '10px',
  },
  nameStack: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  warningTooltip: {
    marginRight: '10px',
  },
};

type Props = {
  isFetching?: boolean;
  isError?: boolean;
  studentWorkBreakdown?: StudentWork[];
};

type RowContent = Omit<StudentWork, 'student'> & { student: StudentWork };

enum Filter {
  finished = 'Finished',
  inProgress = 'In Progress',
  misconceptions = 'Misconceptions',
}

export const StudentProgressView = ({ isFetching, isError, studentWorkBreakdown }: Props) => {
  const [shouldShowStudentResponseTable, setShouldShowStudentResponseTable] = useState(false);
  const [lessonInstanceId, setLessonInstanceId] = useState(0);
  const [selectedRowNumber, setSelectedRowNumber] = useState<number | null>(null);
  const textCellStyle = { whiteSpace: 'pre', wordBreak: 'normal', overflow: 'hidden' };
  const [filterText, setFilterText] = useState('');
  const [selectedFilter, setSelectedFilter] = useState<Filter | null>(null);

  const filters = [
    { name: Filter.finished, label: 'Finished' },
    { name: Filter.inProgress, label: 'In Progress' },
    { name: Filter.misconceptions, label: 'Misconceptions' },
  ];

  const sensitiveInputRenderer = (params: { value: boolean }) =>
    params.value ? (
      <Warning color='error' sx={{ display: 'flex', justifyContent: 'center' }} data-testid='sensitive-warning' />
    ) : null;

  const dateValueFormatter = (params: { value: string }) => {
    if (!params.value) return '';
    return dayjs(params.value).format('MMM D, h:mm A');
  };

  const studentFormatter = (params: { value: StudentWork }) => params.value.student;

  const scoreValueFormatter = (params: { value: number }) => {
    if (params.value === null || params.value === undefined) return '';
    const score = Math.round(params.value * 100);
    return `${score}%`;
  };

  const misconceptionValueFormatter = (params: { value: Misconception[] }) => {
    const count = params.value.length;
    return `${count} misconception${count === 1 ? '' : 's'}`;
  };

  const studentProgressColumns = [
    {
      field: 'has_sensitive_input',
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      resizable: false,
      cellStyle: { padding: '0px', display: 'flex', justifyContent: 'center' },
      cellRenderer: sensitiveInputRenderer,
    },
    {
      field: 'student',
      headerName: 'Student',
      flex: 2,
      cellStyle: textCellStyle,
      valueFormatter: studentFormatter,
    },
    {
      field: 'source',
      headerName: 'Source',
      flex: 1,
      cellStyle: textCellStyle,
    },
    {
      field: 'external_user_id',
      headerName: 'External User ID',
      flex: 1,
      cellStyle: textCellStyle,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      cellStyle: textCellStyle,
    },
    {
      field: 'started_at',
      headerName: 'Started At',
      flex: 1,
      cellStyle: textCellStyle,
      valueFormatter: dateValueFormatter,
      useValueFormatterForExport: false,
    },
    {
      field: 'finished_at',
      headerName: 'Finished At',
      flex: 1,
      cellStyle: textCellStyle,
      valueFormatter: dateValueFormatter,
      useValueFormatterForExport: false,
    },
    {
      field: 'assessment_score',
      headerName: 'Score',
      flex: 1,
      cellStyle: textCellStyle,
      valueFormatter: scoreValueFormatter,
    },
    {
      field: 'misconceptions',
      headerName: 'Misconceptions',
      flex: 2,
      cellStyle: textCellStyle,
      valueFormatter: misconceptionValueFormatter,
    },
  ];

  const studentProgressRows = studentWorkBreakdown?.map(student => ({
    has_sensitive_input: student.has_sensitive_input,
    student,
    status: student.status,
    started_at: student.started_at,
    finished_at: student.finished_at,
    misconceptions: student.misconceptions,
    lesson_instance_id: student.lesson_instance_id,
    source: student.source,
    external_user_id: student.external_user_id,
    assessment_score: student.assessment_score,
  }));

  const handleStudentClick = (e: { data: RowContent; rowIndex: number | null }) => {
    setShouldShowStudentResponseTable(true);
    setSelectedRowNumber(e.rowIndex);
    setLessonInstanceId(e.data.student.lesson_instance_id);
  };

  const onFilterTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value);
  };

  const isExternalFilterPresent = selectedFilter !== null;

  const doesExternalFilterPass = (node: IRowNode<RowContent>) => {
    const student = node.data;
    if (!student) {
      return true;
    }
    const { status, misconceptions } = student;
    if (selectedFilter === Filter.finished && status !== 'Finished') return false;
    if (selectedFilter === Filter.inProgress && status !== 'In Progress') return false;
    if (selectedFilter === Filter.misconceptions && misconceptions.length === 0) return false;
    return true;
  };

  return (
    <div>
      {isError && <Typography variant='bodyMedium'>There was an error loading student progress.</Typography>}
      {studentWorkBreakdown && (
        <Drawer
          anchor='right'
          data-testid='student-panel'
          open={shouldShowStudentResponseTable}
          onClose={() => setShouldShowStudentResponseTable(false)}
          PaperProps={{
            sx: {
              width: '400px',
              padding: 0,
              backgroundColor: 'surface.dark',
            },
          }}
          // This is a workaround to remove the backdrop from the drawer while
          // still allowing the drawer to close when the user clicks outside of it
          ModalProps={{
            slotProps: {
              backdrop: { sx: { background: 'none' } },
            },
          }}
        >
          <StudentPanel
            lessonInstanceId={lessonInstanceId}
            closePanel={() => setShouldShowStudentResponseTable(false)}
            rowNumber={selectedRowNumber}
            studentWorkBreakdown={studentWorkBreakdown}
          />
        </Drawer>
      )}
      <Row gap={1}>
        <TextField
          sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}
          label='Search for students'
          variant='outlined'
          onChange={onFilterTextChange}
          size='small'
          InputProps={{
            startAdornment: <Search sx={{ color: 'divider' }} />,
          }}
          value={filterText}
          data-testid='student-search'
        />
        {filters.map(filter => (
          <Chip
            key={filter.name}
            label={filter.label}
            variant={selectedFilter === filter.name ? 'filled' : 'outlined'}
            data-testid={filter.name}
            onClick={() =>
              setSelectedFilter(prev => {
                if (prev === filter.name) return null;
                return filter.name;
              })
            }
          />
        ))}
      </Row>
      <Box sx={styles.studentProgressReport}>
        <DataGrid
          rows={studentProgressRows}
          columns={studentProgressColumns}
          noRowsText='No students have started this lesson yet'
          onRowClicked={handleStudentClick}
          filterText={filterText}
          doesExternalFilterPass={doesExternalFilterPass}
          isExternalFilterPresent={isExternalFilterPresent}
          loading={isFetching}
        />
      </Box>
    </div>
  );
};
