import React from 'react';
import { CatalogLayout, loader as catalogLayoutLoader } from 'components/CatalogLayout/CatalogLayout';
import { CatalogUnit } from 'components/CatalogUnit/CatalogUnit';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import { KyronLayout } from 'components/KyronLayout/KyronLayout';
import { User } from 'controllers/types';
import { RouteObject } from 'react-router-dom';
import { Home } from '../Home/Home';

export const k12Routes = (user: User | null): RouteObject[] => [
  {
    path: '/k12',
    element: <KyronLayout user={user} />,
    loader: () => user,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            element: <CatalogLayout />,
            loader: catalogLayoutLoader,
            children: [
              {
                errorElement: <ErrorPage />,
                children: [
                  {
                    index: true,
                    element: <Home />,
                  },
                  {
                    path: 'demo',
                    element: <Home />,
                  },
                  {
                    path: 'math',
                    element: <Home />,
                  },
                  {
                    path: 'units',
                    children: [
                      {
                        path: ':unitId',
                        element: <CatalogUnit />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
