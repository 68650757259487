import { AppCtx, AppState } from '../AppContext/types';
import { EditorCtx, EditorState } from '../Studio/Editor/EditorContext';

export type KyronWindowObject = {
  appContext: AppCtx;
  appState: AppState;
  editorContext: EditorCtx;
  editorState: EditorState;
};

// This adds the passed `kyronWindowObject` argument to the `window.kyron` object in development mode for debugging.
// This enables developers to access the app context and states from the browser console.
export function addInKyronWindowObject(kyronWindowObject: Record<string, unknown>): void {
  if (process.env.NODE_ENV === 'development') {
    window.kyron = { ...window.kyron, ...kyronWindowObject };
  }
}
