import { Box, styled } from '@mui/material';

export const InfoBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 9,
  padding: '0.8em',
  borderRadius: '0.8em',
  fontSize: '0.85rem',
  fontWeight: 500,
  background: theme.palette.tertiaryContainer.main,
  color: theme.palette.tertiaryContainer.contrastText,
  boxShadow: theme.shadows[2],
}));
