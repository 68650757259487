import { createContext, useContext, useEffect, useState } from 'react';
import { useInitEditorContext } from '../Studio/Editor/EditorContext';
import { AppCtx, AppState } from './types';
import { addInKyronWindowObject } from '../utils/windowUtil';
import { initialBackgroundTasks, useUserNotifications } from '../../channels/UserNotifications';

export const AppContext = createContext({} as AppCtx);
export const useAppContext = () => useContext(AppContext);

/**
 * Initializes the app context that holds the global states of the app.
 */
export const useInitAppContext = () => {
  // Initialize the editor context and distribute it via AppContext
  // (Feel free to add global contexts/states here following the same pattern.)
  const editorContext = useInitEditorContext();

  // APP STATE
  const [appState, $$setAppState] = useState<AppState>({
    notifications: {
      backgroundTasks: initialBackgroundTasks,
    },
  });

  // This is a wrapper to make sure the appState is always updated with the new state and never overridden entirely
  function setAppState(newState?: Partial<AppState> | ((prevState: AppState) => Partial<AppState>)) {
    // Handle the callback function case
    if (typeof newState === 'function') return $$setAppState(p => ({ ...p, ...newState(p) }));
    // Handle the object case
    $$setAppState(p => ({ ...p, ...newState }));
  }

  const data = useUserNotifications();
  const stringifiedData = JSON.stringify(data); // for deep comparison for change detection
  useEffect(setUserNotifications, [data, stringifiedData]);
  function setUserNotifications() {
    // TODO(Ege): We need a more sophisticated setting mechanism here. Probably by adjusting the data
    //  we receive and having a "type" field in it to determine where in the user notifications to set it.
    //  e.g. { type: 'backgroundTasks', data }
    //  Otherwise, we are risking to override some notifications with the new ones. Especially if we receive different
    //  types of notifications in data.
    setAppState(prev => ({ notifications: { ...prev.notifications, ...data } }));
  }

  const appContext: AppCtx = { appState, notifications: appState.notifications, setAppState, editorContext };

  addInKyronWindowObject({ appContext, appState, editorContext, editorState: editorContext.editorState });

  return appContext;
};
