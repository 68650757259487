import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { ContactUs } from 'components/ContactUs/ContactUs';
import { AboutKyron } from 'components/AboutKyron/AboutKyron';
import { Pricing } from 'components/Pricing/Pricing';
import { PdfViewer } from 'components/common/PdfViewer';
import { KyronLayout } from 'components/KyronLayout/KyronLayout';

export const marketingRoutes: RouteObject[] = [
  {
    path: 'contact-us',
    element: <ContactUs />,
  },
  {
    path: 'about',
    element: <AboutKyron />,
  },
  {
    path: 'team',
    element: <Navigate to='/about' replace />,
  },
  {
    path: 'pricing',
    element: <Pricing />,
  },
  {
    path: 'terms_of_service',
    element: <KyronLayout user={null} />,
    children: [
      {
        index: true,
        element: <PdfViewer fileName='terms_of_service.pdf' />,
      },
    ],
  },
  {
    path: 'privacy_policy',
    element: <KyronLayout user={null} />,
    children: [
      {
        index: true,
        element: <PdfViewer fileName='privacy_policy.pdf' />,
      },
    ],
  },
];
