import React from 'react';
import { PlaylistEdit } from 'components/Studio/Library/PlaylistEdit';
import { PlaylistNew } from 'components/Studio/Library/PlaylistNew';
import { OrganizationAnalytics } from 'components/Studio/OrganizationAnalytics/OrganizationAnalytics';
import { StudentDashboard } from 'components/Studio/StudentDashboard/StudentDashboard';
import { TutorEdit } from 'components/Studio/Tutors/TutorEdit';
import { TutorNew } from 'components/Studio/Tutors/TutorNew';
import { Tutors } from 'components/Studio/Tutors/Tutors';
import { StudioLayout } from 'components/StudioLayout/StudioLayout';
import { ProtectedRoute } from 'components/utils/ProtectedRoute';
import { User } from 'controllers/types';
import { RouteObject, Navigate } from 'react-router-dom';
import { MinimalLessonLayout } from 'components/Lesson/LessonLayout/LessonLayout';
import { PreviewPlayer } from 'components/Player/PreviewPlayer';
import { editorRoutes } from './EditorRoutes';
import { courseBuilderRoutes } from './CourseBuilderRoutes';
import { MisconceptionEditor, loader as misconceptionLoader } from '../../MisconceptionEditor/MisconceptionEditor';
import { CourseEditor } from '../../CourseEditor/CourseEditor';
import { UnitEditor } from '../../UnitEditor/UnitEditor';
import { TestRunner } from '../../AutomatedTest/TestRunner';
import { libraryRoutes } from './LibraryRoutes';
import { classroomRoutes } from './ClassroomRoutes';
import { settingsRoutes } from './SettingsRoutes';

export const studioRoutes = (user: User | null): RouteObject[] => [
  {
    path: 'studio/courses/:lessonId/preview',
    element: <MinimalLessonLayout />, // This is a non-protected route, accessible to anyone with link
    children: [
      {
        index: true,
        element: <PreviewPlayer />,
      },
    ],
  },
  {
    element: <ProtectedRoute user={user} />,
    children: [
      ...editorRoutes,
      ...courseBuilderRoutes,
      {
        element: <StudioLayout />,
        path: 'studio',
        children: [
          {
            index: true,
            element: <Navigate to='/studio/courses' replace />,
          },
          ...libraryRoutes,
          ...classroomRoutes,
          ...settingsRoutes,
          {
            path: 'playlists/new',
            element: <PlaylistNew />,
          },
          {
            path: 'playlists/:playlistId',
            element: <PlaylistEdit />,
          },
          {
            path: 'tutors',
            element: <Tutors />,
          },
          {
            path: 'tutors/new',
            element: <TutorNew />,
          },
          {
            path: 'tutors/:tutorId',
            element: <TutorEdit />,
          },
          {
            path: 'misconception_editor',
            element: <MisconceptionEditor />,
            loader: misconceptionLoader,
          },
          {
            path: 'course_editor',
            element: <CourseEditor />,
          },
          {
            path: 'unit_editor',
            element: <UnitEditor />,
          },
          {
            path: 'test_runner',
            element: <TestRunner />,
          },
          {
            path: 'organization_analytics',
            element: <OrganizationAnalytics />,
          },
          {
            path: 'student_dashboard',
            element: <StudentDashboard />,
          },
        ],
      },
    ],
  },
];
