import React from 'react';
import { RouteObject } from 'react-router-dom';
import { TeacherDashboard } from '../../Teacher/TeacherDashboard';
import { AssignmentsPage } from '../../Teacher/AssignmentsPage';
import { AssignmentDetails } from '../../Teacher/AssignmentDetails';

export const classroomRoutes: RouteObject[] = [
  {
    path: 'classrooms',
    children: [
      {
        index: true,
        element: <TeacherDashboard />,
      },
      {
        path: ':classroomId',
        element: <AssignmentsPage />,
      },
      {
        path: ':classroomId/assignments/:lessonCollectionId',
        element: <AssignmentDetails />,
      },
    ],
  },
];
