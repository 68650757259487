import React, { useEffect, useRef } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  GlobalStyles,
  IconButton,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ArrowBack, MoreVert } from '@mui/icons-material';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { AppBar } from 'components/AppBar/AppBar';
import { NoResources } from 'components/NoResources';
import { KyronMenu } from 'components/KyronMenu';
import { useLessonQuery, useLessonSectionsQuery } from 'controllers/react-query';
import { BackgroundTask, KyronID } from 'controllers/types';
import { HelpButton } from 'components/HelpButton/HelpButton';
import { SectionListSkeleton, SegmentDetailSkeleton } from './EditorSkeleton';
import { generateUIMessageForBTError } from '../../BackgroundTasks/utils';
import { LessonNameField } from '../components/LessonNameField';
import { PublishButton } from '../Publish/PublishButton';
import { LessonStatusTags } from './LessonStatusTags';
import { SectionList } from './SectionList';
import { useLessonBTWatcher } from '../../BackgroundTasks/backgroundTaskWatchers';
import { KyronEvents } from '../../utils/KyronEvents';
import { DismissBackgroundTaskButton } from '../../BackgroundTasks/DismissBackgroundTaskButton';
import { DevTools } from './DevTools';
import { useRedirectToLatestDraft } from '../../../controllers/react-query/lessonHooks';

// export for testing
export function useLessonAndSectionsQuery(lessonId: number) {
  // TODO(Ege/cleanup): Do we need these composite hooks? Should we have dedicated endpoints for these to de-clutter FE?
  const { error: lessonError, isFetching: isLoadingLesson, data: lesson } = useLessonQuery(lessonId);
  const {
    error: sectionError,
    isFetching: isLoadingSections,
    data: lessonSectionsData,
  } = useLessonSectionsQuery(lessonId);
  const { lesson_sections: lessonSections = [] } = lessonSectionsData || {};
  const isLoading = isLoadingLesson || isLoadingSections;
  const errors: Error[] = [lessonError, sectionError].filter((e): e is Error => !!e);

  return { isLoading, errors, lesson, lessonSections };
}

export function Editor() {
  useRedirectToLatestDraft();
  const isMobileView = useMediaQuery((t: Theme) => t.breakpoints.down('sm'));
  const { lessonId } = useParams<{ lessonId: string }>();
  const location = useLocation();
  const { lesson, lessonSections, errors: sectionErrors } = useLessonAndSectionsQuery(Number(lessonId));
  const userMessageRef = useRef<HTMLDivElement>(null);

  const { active: _, failed: __, ...btStatus } = useLessonBTWatcher(lessonId);
  const isAnyActionBlockingTaskInProgress =
    btStatus.isLessonPlanningInProgress ||
    btStatus.isLessonPublishingInProgress ||
    btStatus.isVideoGenerationInProgress ||
    btStatus.isVideoRegenerationInProgress;

  const isCreatingLesson = lessonSections.length === 0 && btStatus.isLessonPlanningInProgress;

  const menuActions = (
    <>
      <Button component={Link} to='analytics' variant='outlined' disabled={isAnyActionBlockingTaskInProgress}>
        Analytics
      </Button>

      <Button
        component={Link}
        to='settings'
        state={{ previousLocation: location }}
        variant='outlined'
        disabled={isAnyActionBlockingTaskInProgress}
      >
        Settings
      </Button>

      <Button
        variant='outlined'
        disabled={isAnyActionBlockingTaskInProgress}
        component={Link}
        target='_blank'
        to={`/studio/courses/${lessonId}/preview`}
        onClick={() => {
          if (!lessonId) return;
          KyronEvents.sendEvent(KyronEvents.names.PREVIEW_CREATED_COURSE, { lesson_id: lessonId });
        }}
      >
        Preview
      </Button>

      <PublishButton lesson={lesson} disabled={isAnyActionBlockingTaskInProgress} />
    </>
  );

  const lessonNameField = lesson ? <LessonNameField lesson={lesson} /> : 'Loading...';

  // This effect will scroll the user message into view when it is shown
  useEffect(scrollUserMessageInView, [btStatus.isAnyInProgress]);
  function scrollUserMessageInView() {
    if (btStatus.isAnyInProgress && userMessageRef.current) {
      // optional chaining with ?. is NOT for here, they already have a check for current and scrollIntoView
      // is on the prototype of the current object. However, it is impossible to mock this logic here and for
      // testing purposes, I need to use optional chaining. If you remove them, you will realize that the tests
      // will fail.
      userMessageRef.current?.scrollIntoView?.({ behavior: 'smooth', inline: 'start', block: 'center' });
    }
  }

  if (!lessonId) return <NoResources message='No course selected' />;

  return (
    <>
      <GlobalStyles styles={theme => ({ body: { backgroundColor: theme.palette.background.paper } })} />
      <AppBar
        showLogo={false}
        title={isMobileView && lessonNameField}
        navButton={
          <>
            <IconButton to='/studio/courses' component={Link}>
              <ArrowBack />
            </IconButton>
            <DevTools />
          </>
        }
        actions={
          isMobileView ? (
            <KyronMenu menuIcon={<MoreVert />}>
              <Stack m={1} gap={1}>
                {menuActions}
              </Stack>
            </KyronMenu>
          ) : (
            menuActions
          )
        }
        mainToolBarSx={{ bgcolor: 'background.paper' }}
      />

      <Box px={2}>
        {sectionErrors.length > 0 ? (
          <Stack gap={1} ml={2} mr={2}>
            {sectionErrors.map(error => (
              <Alert key={error.message} severity='error'>
                {error.message}
              </Alert>
            ))}
          </Stack>
        ) : null}

        <Stack sx={{ maxWidth: '1380px', m: '0 auto', px: 6, pb: 8, gap: 1 }}>
          <EditorNotificationStack lessonId={lessonId} />

          <LessonStatusTags lesson={lesson} />
          <Box>
            <Typography variant='displayMedium'>{lesson?.name}</Typography>
          </Box>
          <Box height={24} />
          <Stack direction={{ xs: 'column', sm: 'row' }} gap={8}>
            <Stack flex='0 0 280px' gap={4} position={{ xs: 'static', sm: 'sticky' }} top={64} height='100%'>
              {isCreatingLesson ? <SectionListSkeleton /> : <SectionList lessonSections={lessonSections} />}
            </Stack>
            {isCreatingLesson ? <SegmentDetailSkeleton /> : <Outlet />}
          </Stack>
        </Stack>
      </Box>
      <HelpButton />
    </>
  );
}

function EditorErrorStackItem({ title, backgroundTask }: { title?: string; backgroundTask?: BackgroundTask }) {
  return backgroundTask ? (
    <Alert variant='standard' severity='error' action={<DismissBackgroundTaskButton backgroundTask={backgroundTask} />}>
      <AlertTitle>{title}</AlertTitle>
      {generateUIMessageForBTError(backgroundTask)}
    </Alert>
  ) : null;
}

function ProgressStackItem({ message, backgroundTask }: { message?: string; backgroundTask?: BackgroundTask }) {
  return backgroundTask ? (
    <Alert severity='info' icon={<CircularProgress size={18} />}>
      {message ? `${message}: ` : ''}
      {backgroundTask.message}
    </Alert>
  ) : null;
}

function EditorNotificationStack({ lessonId }: { lessonId: KyronID }) {
  // TODO(Ege/cleanup): If you look below, you will see that each stack item has a "message" or "title" prop.
  //   Ideally, we should have them and all would be retrieved from the backgroundTask. However, that needs some more
  //   changes like having a "type" field in the backgroundTask to distinguish between different types of tasks. Or,
  //   updating all the background task messaging to follow a pattern that is suitable for UI.
  //   This was a quick decluttering of the Editor UI and need more improvements.
  const { active, failed, ...btStatus } = useLessonBTWatcher(lessonId);
  return (
    <Stack gap={1}>
      {btStatus.isAnyInProgress ? (
        <Stack data-testid='pending-task-info'>
          <ProgressStackItem backgroundTask={active.lessonPlanning[0]} />
          <ProgressStackItem message='Video generation' backgroundTask={active.videoGeneration[0]} />
          <ProgressStackItem message='Publishing the lesson' backgroundTask={active.lessonPublishing[0]} />
          <ProgressStackItem message='Translating the lesson' backgroundTask={active.lessonTranslation[0]} />
          <ProgressStackItem message='Some videos are being generated' backgroundTask={active.videoRegeneration[0]} />
        </Stack>
      ) : null}

      {btStatus.isAnyFailed ? (
        <>
          <EditorErrorStackItem title='Creation Error:' backgroundTask={failed.lessonPlanning[0]} />
          <EditorErrorStackItem title='Publish Error:' backgroundTask={failed.lessonPublishing[0]} />
          <EditorErrorStackItem title='Translation Error:' backgroundTask={failed.lessonTranslation[0]} />
          <EditorErrorStackItem title='Video Generation Error:' backgroundTask={failed.videoGeneration[0]} />
          <EditorErrorStackItem title='Video Regeneration Error:' backgroundTask={failed.videoRegeneration[0]} />
        </>
      ) : null}
    </Stack>
  );
}
