import React from 'react';
import { RouteObject, redirect } from 'react-router-dom';
import { Player } from 'components/Player/Player';
import { LessonLayout, MinimalLessonLayout } from 'components/Lesson/LessonLayout/LessonLayout';
import { LessonNavigator } from 'components/Lesson/LessonNavigator/LessonNavigator';
import { CatalogLesson } from 'components/CatalogLesson/CatalogLesson';
import { LtiPlatformLessonsIframeView } from 'components/LtiPlatform/LtiPlatformLessonsIframeView';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import {
  redirectNewUrlToVideoIfSingleLesson,
  redirectOldUrlToVideoIfSingleLesson,
} from 'controllers/react-query/lessonContainerHooks';

export const lessonRoutes: RouteObject[] = [
  {
    // BEWARE:
    // This path and paths under it is being used to render lessons in iframe
    // If any changes happen here, make sure to reflect those changes in
    // app/services/embed_service.rb
    path: 'units/:unitId/lessons/:lessonId',
    element: <CatalogLesson />,
    loader: async ({ params }) => {
      if (params && params.lessonId) {
        return redirectOldUrlToVideoIfSingleLesson(params.lessonId);
      }
      return null;
    },
  },
  {
    // BEWARE:
    // This path and paths under it is being used to render lessons in iframe
    // If any changes happen here, make sure to reflect those changes in
    // app/services/embed_service.rb
    path: 'lesson_collections/:lessonCollectionId',
    element: <CatalogLesson />,
    loader: async ({ params }) => {
      if (params && params.lessonCollectionId) {
        return redirectNewUrlToVideoIfSingleLesson(params.lessonCollectionId);
      }
      return null;
    },
  },
  {
    // BEWARE:
    // This path and paths under it is being used to render lessons in iframe
    // If any changes happen here, make sure to reflect those changes in
    // app/services/embed_service.rb
    path: '/lti_platforms/:platformId/lessons',
    element: <LtiPlatformLessonsIframeView />,
  },
  {
    // BEWARE:
    // This path and paths under it is being used to render lessons in iframe
    // If any changes happen here, make sure to reflect those changes in
    // app/services/embed_service.rb
    path: '/video_player/:lessonId',
    errorElement: <ErrorPage />,
    element: <MinimalLessonLayout />,
    children: [
      {
        index: true,
        element: <Player />,
      },
      {
        path: 'segments/:segmentId',
        element: <Player />,
      },
    ],
  },
  {
    path: 'courses/:lessonCollectionId',
    element: <LessonLayout />,
    children: [
      {
        index: true,
        element: <LessonNavigator />,
      },
      {
        path: ':lessonId',
        element: <Player />,
      },
    ],
  },
  {
    path: 'lessons/:lessonCollectionId',
    loader: ({ params }) => redirect(`/courses/${params.lessonCollectionId}`),
  },
  {
    path: 'lessons/:lessonCollectionId/:lessonId',
    loader: ({ params }) => redirect(`/courses/${params.lessonCollectionId}/${params.lessonId}`),
  },
];
