import React from 'react';
import { useParams } from 'react-router-dom';
import { useLessonQuery } from 'controllers/react-query';
import { LessonCreationStage } from 'controllers/types';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { MultiStepForm } from '../../Components/MultiStepForm';
import { CourseOverviewForm } from './CourseOverviewForm';
import { CourseModulesForm } from './CourseModulesForm/CourseModulesForm';
import { defaultValues } from './formValues';

export function CourseBuilder() {
  const { lessonId } = useParams<{ lessonId: string }>();
  const { error: lessonError, isFetching: isLoadingLesson, data: lesson } = useLessonQuery(lessonId);

  if (lessonError) {
    return <div>Something went wrong...</div>; // TODO: proper error handling
  }

  if (isLoadingLesson && !lesson) {
    return <LoadingIndicator overlay />;
  }

  const steps = [
    { id: LessonCreationStage.overview, form: <CourseOverviewForm lesson={lesson} /> },
    { id: LessonCreationStage.module_outline, form: <CourseModulesForm lesson={lesson} /> },
  ];

  const currentStep = steps.findIndex(s => s.id === lesson?.creation_stage) || 0;

  return (
    <MultiStepForm
      steps={steps}
      defaultValues={{
        ...defaultValues,
        courseOverview: {
          ...defaultValues.courseOverview,
          name: lesson?.name,
          audience: lesson?.audience,
          objective: lesson?.objective,
          description: lesson?.description,
          intended_time: lesson?.intended_time,
        },
      }}
      currentStep={currentStep}
    />
  );
}
