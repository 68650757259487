import { School } from '@mui/icons-material';
import { Stack, SxProps, Typography, Button } from '@mui/material';
import React from 'react';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { KyronEvents } from 'components/utils/KyronEvents';
import { BasicLessonPayload } from 'controllers/types';

type ExamplePromptCardProps = {
  prompt: BasicLessonPayload;
  loading: boolean;
  id: string;
  onClick: (prompt: BasicLessonPayload) => void;
  dense?: boolean;
  sx?: SxProps;
};

export function ExamplePromptButton({ prompt, loading, id, onClick, dense = false, sx }: ExamplePromptCardProps) {
  return (
    <Button
      variant='outlined'
      sx={{
        bgcolor: '#FFFFFFCC',
        borderRadius: 2,
        borderColor: dense ? 'default' : 'transparent',
        padding: 2,
        minWidth: 0,
        textAlign: 'left',
        '&:hover': {
          bgcolor: dense ? 'default' : '#FFFFFFCC',
        },
        ...sx,
      }}
      aria-labelledby={id}
      onClick={() => {
        onClick(prompt);
        KyronEvents.sendEvent(KyronEvents.names.USE_EXAMPLE_PROMPT);
      }}
      data-testid='example-prompt-card'
    >
      <Stack gap={1} alignItems='flex-start' width='100%'>
        {!dense && <School color='primary' />}
        <Typography variant={dense ? 'labelSmall' : 'labelMedium'} color='primary' component='span'>
          Example course
        </Typography>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <Typography
            variant={dense ? 'bodySmall' : 'bodyMedium'}
            color='primaryContainer.contrastText'
            id={id}
            data-testid='example-prompt'
            sx={{ whiteSpace: 'normal' }}
          >
            {prompt.lessonTitle}
          </Typography>
        )}
      </Stack>
    </Button>
  );
}
