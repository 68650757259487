import React, { useEffect, useState } from 'react';
import { IconButton, Typography, TextField, Stack, Popover } from '@mui/material';
import { ArrowForward, CampaignOutlined } from '@mui/icons-material';
import { KyronEvents } from 'components/utils/KyronEvents';
import { Row } from 'components/Row/Row';

const HUBSPOT_NEWSLETTER_FORM_ID = '881f1894-ed5a-4b21-9dc9-e2521ab6a042';
const HUBSPOT_PORTAL_ID = '24326849';

export function Newsletter() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const portalId = HUBSPOT_PORTAL_ID;

  // Show the popover after 3 seconds on the homepage
  useEffect(() => {
    if (buttonRef.current) {
      const timer = setTimeout(() => {
        setAnchorEl(buttonRef.current);
      }, 3000);
      return () => {
        setAnchorEl(null);
        clearTimeout(timer);
      };
    }
  }, []);

  // Make sure the popover is closed before changing back the submitted state
  const closePopover = () => {
    setAnchorEl(null);
    const popover = document.querySelector('.MuiPopover-root');
    if (popover) {
      popover.addEventListener('transitionend', () => {
        setSubmitted(false);
      });
    } else {
      setSubmitted(false);
    }
  };

  // Close the thank you state after 3 seconds
  useEffect(() => {
    if (submitted) {
      const timer = setTimeout(() => {
        closePopover();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [submitted]);

  // On submit, send the email to HubSpot's newsletter signup form
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!portalId) return;

    KyronEvents.sendEvent(KyronEvents.names.SUBMIT_NEWSLETTER_FORM);
    const path = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${HUBSPOT_NEWSLETTER_FORM_ID}`;

    const body = {
      fields: [{ type: '0-1', name: 'email', value: email }],
    };

    fetch(path, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    }).then(() => {
      setSubmitted(true);
      setEmail('');
    });
  };

  return (
    <>
      <IconButton
        ref={buttonRef}
        data-testid='newsletter-button'
        aria-label='Newsletter'
        onClick={e => setAnchorEl(e.currentTarget)}
        variant='filled'
        sx={{
          boxShadow: 2,
          bgcolor: 'surface.main',
          color: 'primary.main',
          position: 'fixed',
          bottom: '24px',
          right: '24px',
          zIndex: 1000,
          '&:hover': { bgcolor: 'surface.dark' },
          '&:focus': { bgcolor: 'surface.main' },
        }}
      >
        <CampaignOutlined />
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        onClose={closePopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: -4,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        disableScrollLock
        data-testid='newsletter-popover'
      >
        {submitted ? (
          <Stack sx={{ p: 2, borderRadius: 2 }}>
            <Typography>Thanks!</Typography>
          </Stack>
        ) : (
          <Stack gap={1} sx={{ p: 2, borderRadius: 2, width: '448px' }}>
            <Typography>
              Let&apos;s keep in touch! Sign up for our newsletter to learn about product updates and events.
            </Typography>
            <form onSubmit={handleSubmit} data-testid='newsletter-form'>
              <Row gap={1}>
                <TextField
                  size='small'
                  type='email'
                  name='email'
                  placeholder='Enter your email address'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                  sx={{ width: '100%' }}
                />
                <IconButton
                  type='submit'
                  sx={{ bgcolor: 'primaryContainer.main', color: 'primary.main' }}
                  data-testid='newsletter-submit'
                >
                  <ArrowForward />
                </IconButton>
              </Row>
            </form>
          </Stack>
        )}
      </Popover>
    </>
  );
}
