import React, { useState } from 'react';
import { Stack, Typography, styled, Container, Button, ButtonProps, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useInitLesson } from 'controllers/react-query/lessonHooks';

enum ActivityType {
  Lecture = 'lecture',
  Discussion = 'discussion',
  Assessment = 'assessment',
  Course = 'course',
}
const activityTypes = [
  { id: ActivityType.Lecture, description: 'Create a video lecture' },
  { id: ActivityType.Discussion, description: 'Create a discussion' },
  { id: ActivityType.Assessment, description: 'Create an assessment' },
];

const ActivityButton = styled(({ children, ...props }: ButtonProps) => (
  <Button disableRipple fullWidth {...props}>
    <Typography variant='bodyLarge'>{children}</Typography>
  </Button>
))(({ theme }) => ({
  // styles here
  borderRadius: 16,
  padding: 16,
  background: theme.palette.background.default,
  boxShadow: theme.shadows[2],
  color: theme.palette.text.primary,
  fontSize: 'bodyLarge',

  '&:hover': {
    background: theme.palette.background.default,
    boxShadow: theme.shadows[2],
  },

  '&:active': {
    boxShadow: theme.shadows[1],
  },

  '&:focus-visible': {
    boxShadow: theme.shadows[2],
    outline: `1px solid ${theme.palette.text.primary}`,
    '&:active': {
      boxShadow: theme.shadows[1],
    },
  },
}));

const progressIndicator = <CircularProgress size={24} color='info' />;

export function ActivitySelector() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedActivity, setSelectedActivity] = useState<ActivityType | null>();

  const { mutateAsync: initNewLesson, isPending } = useInitLesson();

  const startCourseBuilder = () => {
    setSelectedActivity(ActivityType.Course);
    initNewLesson({})
      .then(lesson => {
        navigate(`/create/course/${lesson.id}`);
      })
      .catch(error => {
        enqueueSnackbar(`Something went wrong: ${error.message}`, { variant: 'error' });
      })
      .finally(() => {
        setSelectedActivity(null);
      });
  };

  return (
    <Container
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      maxWidth='md'
    >
      <Stack gap={15}>
        <ActivityButton
          onClick={startCourseBuilder}
          data-testid='create-course'
          disabled={isPending}
          startIcon={selectedActivity === ActivityType.Course ? progressIndicator : null}
        >
          Create a course
        </ActivityButton>

        <Stack gap={3} display='flex' alignItems='center'>
          <Typography variant='bodyLarge'>Create a course activity</Typography>

          <Stack direction={{ xs: 'column', sm: 'row' }} gap={3} justifyContent='center' width='100%'>
            {activityTypes.map(activity => (
              <ActivityButton
                key={activity.id}
                disabled={false} // make disabled depending on the action it calls
                startIcon={selectedActivity === activity.id ? progressIndicator : null}
              >
                {activity.description}
              </ActivityButton>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}
