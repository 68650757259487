import React, { useContext, useState } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Typography,
  Collapse,
  Fade,
  TextField,
  Popover,
  Button,
  CircularProgress,
} from '@mui/material';
import { DeleteOutlined, DragIndicator } from '@mui/icons-material';
import { SortableItemContext } from '../../../SortableList/components/SortableItem';
import { Row } from '../../../Row/Row';

type Props = {
  position: number;
  title: string;
  objectives: string;
  collapsed?: boolean;
  onDeleteModule: () => void;
  disableActions: boolean;
  isOverlayItem: boolean | undefined;
};

export function CourseModuleCard({
  position,
  title,
  objectives,
  collapsed,
  onDeleteModule,
  disableActions,
  isOverlayItem,
}: Props) {
  const { attributes, listeners, ref, isDragging } = useContext(SortableItemContext);
  const [isCardHovered, setIsCardHovered] = useState(false);

  return (
    <Paper
      variant={isOverlayItem ? 'elevation' : 'outlined'}
      elevation={2}
      sx={{ bgcolor: 'background.default', borderRadius: 1, overflow: 'hidden', width: '100%' }}
    >
      <Box
        onMouseEnter={() => setIsCardHovered(true)}
        onMouseLeave={() => setIsCardHovered(false)}
        onFocusCapture={() => setIsCardHovered(true)}
        onBlurCapture={() => setIsCardHovered(false)}
        sx={{ display: 'grid', gridTemplateColumns: `56px 1fr`, gridTemplateRows: `1fr` }}
      >
        <Box
          {...attributes}
          {...listeners}
          ref={ref}
          sx={{
            display: 'grid',
            placeContent: 'center',
            bgcolor: `surfaceContainer.${isCardHovered || isDragging ? 'dark' : 'main'}`,
            color: 'text.secondary',
            position: 'relative',
            height: '100%',
            width: '100%',
            cursor: isDragging ? 'grabbing' : 'grab',
          }}
        >
          <Fade in={!isCardHovered && !isDragging} appear={false} timeout={300}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Typography variant='labelLarge'>{position}</Typography>
            </Box>
          </Fade>
          <Fade in={isCardHovered || isDragging} timeout={300}>
            <DragIndicator />
          </Fade>
        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 56px' }} p={2}>
          <Stack gap={1}>
            <TextField
              fullWidth
              value={title}
              sx={{ fontSize: t => t.typography.titleSmall }}
              size='small'
              data-testid='module-title'
            />
            <Collapse in={!collapsed} timeout='auto' unmountOnExit>
              <TextField multiline fullWidth value={objectives} sx={{ fontSize: t => t.typography.bodyMedium }} />
            </Collapse>
          </Stack>
          <DeleteButtonWithConfirmation disabled={disableActions} onDeleteModule={onDeleteModule} />
        </Box>
      </Box>
    </Paper>
  );
}

function DeleteButtonWithConfirmation({ disabled, onDeleteModule }: { disabled: boolean; onDeleteModule: () => void }) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton
        aria-describedby='delete-module-popover-button'
        data-testid='delete-module-button'
        disabled={disabled}
        onClick={disabled ? () => null : handleClick}
        size='small'
        style={{ alignSelf: 'start', justifySelf: 'end' }}
      >
        <DeleteOutlined fontSize='small' />
      </IconButton>
      <Popover
        id='delete-module-popover-button'
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        transformOrigin={{ vertical: 'center', horizontal: 'left' }}
        slotProps={{ paper: { sx: { p: 1, borderRadius: 1.5, bgcolor: 'background.default' } } }}
        sx={{ ml: 3 }}
      >
        <Typography variant='titleSmall' pl={1}>
          Delete?
        </Typography>
        <Row justifyContent='end' gap={0.5} mt={0.5}>
          <Button onClick={handleClose} size='xs' variant='text' color='info'>
            Cancel
          </Button>
          <Button onClick={onDeleteModule} size='xs' variant='text'>
            {disabled ? <CircularProgress color='info' size='1em' /> : 'Yes'}
          </Button>
        </Row>
      </Popover>
    </>
  );
}
