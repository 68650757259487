import { IconButton } from '@mui/material';
import React from 'react';
import { DeleteOutlined } from '@mui/icons-material';
import { BackgroundTask } from '../../controllers/types';
import { useKyronMutationV2 } from '../../controllers/react-query/kyronMutation';
import { KyronTooltip } from '../KyronTooltip';

export const DismissBackgroundTaskButton = ({ backgroundTask }: { backgroundTask: BackgroundTask }) => {
  const { mutate: dismiss } = useKyronMutationV2('/background_task/:id', {
    method: 'DELETE',
    invalidates: ['/background_tasks'],
  });

  return (
    <KyronTooltip title='Ignore' placement='top'>
      <IconButton onClick={() => dismiss({ id: backgroundTask.id })}>
        <DeleteOutlined fontSize='small' />
      </IconButton>
    </KyronTooltip>
  );
};
