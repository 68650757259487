import React from 'react';
import { Box, Link, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { Row } from 'components/Row/Row';
import { KyronLogo } from 'components/KyronLogo';
import { Link as RouterLink } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { CosmicGradient } from './CosmicGradient';
import { HomepageWrapper } from './HomepageWrapper';
import { HomepageSection } from './HomepageSection';

export function Footer() {
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const { showSpace } = useIntercom();

  const footerLinks = [
    {
      category: 'Product',
      links: [
        { label: 'Help', onClick: () => showSpace('home') },
        { label: 'Pricing', href: '/pricing' },
        { label: 'Terms of service', href: '/terms_of_service' },
        { label: 'Privacy policy', href: '/privacy_policy' },
      ],
    },
    {
      category: 'Company',
      links: [
        { label: 'About', href: '/about' },
        { label: 'Contact us', href: '/contact-us' },
        { label: 'Press', href: 'https://www.prnewswire.com/search/news/?keyword=kyron%20learning' },
      ],
    },
    {
      category: 'Follow us',
      links: [
        { label: 'Blog', href: 'https://blog.kyronlearning.com/' },
        { label: 'LinkedIn', href: 'https://www.linkedin.com/company/kyronlearning/' },
        { label: 'Twitter', href: 'https://twitter.com/kyronlearning' },
        { label: 'Instagram', href: 'https://www.instagram.com/kyronlearning/' },
      ],
    },
  ];

  return (
    <HomepageSection pt={isMobile ? '80px' : '160px'} pb={isMobile ? '80px' : '160px'} bgcolor='#460052'>
      <HomepageWrapper maxWidth='800px' gap='120px'>
        <Stack sx={{ gap: 2, maxWidth: '800px', m: '0 auto', textAlign: 'center' }}>
          <Typography
            variant='displayLarge'
            component='h2'
            sx={{ color: '#FFDED1', fontSize: { sm: '72px', xs: '40px' }, lineHeight: { sm: '78px', xs: '48px' } }}
          >
            Join innovative course creators in creating engaging online lessons
          </Typography>
          {/* <Typography
              variant='headlineLarge'
              sx={{
                color: '#F7D0FC',
                fontSize: { sm: '48px', xs: '32px' },
                lineHeight: { sm: '56px', xs: '40px' },
                fontWeight: 'normal',
              }}
            >
              Try it for free
            </Typography> */}
        </Stack>

        <Row
          sx={{
            gap: { sm: 3, xs: 6 },
            alignItems: { sm: 'flex-start', xs: 'center' },
            flexDirection: { sm: 'row', xs: 'column' },
          }}
        >
          <Box flex={isMobile ? 1 : '0 0 240px'}>
            <KyronLogo fill='#EAB9D1' height='20px' />
          </Box>

          {footerLinks.map(({ category, links }) => (
            <Stack
              gap={isMobile ? 1 : 4}
              flex={isMobile ? '0 0 auto' : 1}
              textAlign={isMobile ? 'center' : 'left'}
              key={category}
            >
              <Typography variant='titleMedium' color='#EAB9D1'>
                {category}
              </Typography>
              <Stack gap={isMobile ? '8px' : '12px'}>
                {links.map(({ label, href, onClick }) => {
                  if (onClick) {
                    return (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <Link
                        key={label}
                        variant='bodyLarge'
                        color='#E5E1E6'
                        onClick={onClick}
                        sx={{ cursor: 'pointer' }}
                        tabIndex={0}
                        data-testid={`footer-link-${label}`}
                      >
                        {label}
                      </Link>
                    );
                  }
                  return (
                    <Link
                      variant='bodyLarge'
                      component={RouterLink}
                      to={href}
                      color='#E5E1E6'
                      key={label}
                      data-testid={`footer-link-${label}`}
                    >
                      {label}
                    </Link>
                  );
                })}
              </Stack>
            </Stack>
          ))}
        </Row>

        <Typography variant='bodyMedium' textAlign='center' color='#F4AEFF'>
          © 2024 Kyron Learning. All rights reserved.
        </Typography>
      </HomepageWrapper>

      <CosmicGradient
        variant='ocean'
        sx={{ position: 'absolute', left: 'calc(50% - 526px)', top: '-280px', zIndex: 0 }}
      />
    </HomepageSection>
  );
}
