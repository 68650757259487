import React from 'react';
import { RouteObject } from 'react-router-dom';
import { MarketplaceLayout } from 'components/Marketplace/MarketplaceLayout/MarketplaceLayout';
import { MarketplaceHome } from 'components/Marketplace/MarketplaceHome/MarketplaceHome';
import { ChannelLayout } from 'components/Channels/ChannelLayout/ChannelLayout';
import { ChannelHome } from 'components/Channels/ChannelHome/ChannelHome';

export const marketplaceRoutes: RouteObject[] = [
  {
    path: 'marketplace',
    element: <MarketplaceLayout />,
    children: [
      {
        index: true,
        element: <MarketplaceHome />,
      },
      {
        path: 'channels/:channelId',
        element: <ChannelLayout />,
        children: [
          {
            index: true,
            element: <ChannelHome />,
          },
          {
            path: 'collections',
            element: <ChannelHome />,
          },
        ],
      },
    ],
  },
];
