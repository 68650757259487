import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  List,
  ListItem,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingIndicator } from '../../../LoadingIndicator';

type ValidationModalProps = {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  error?: Error | null;
  objectives: string[];
  approveButton: React.ReactNode;
};

export const ValidationModal = ({ open, onClose, loading, error, objectives, approveButton }: ValidationModalProps) => {
  const theme = useTheme();

  return (
    <Dialog open={open} onClose={loading ? undefined : onClose} fullWidth data-testid='validation-modal'>
      <DialogTitle>Review enhanced learning objectives</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant='bodyMedium'>
            We’re updating your learning objectives to better align with your course length and ensure they are
            achievable and impactful. This process helps refine objectives to meet best practices while fitting within
            the available time. Please review the results below.
          </Typography>
          {error && <Typography variant='bodyMedium'>There was an error enhancing the learning objectives.</Typography>}
          {loading && (
            <LoadingIndicator spinnerSize='1rem' fontSize='14px' message='Enhancing learning objectives...' />
          )}
          {!loading && objectives.length > 0 && (
            <>
              <List sx={{ listStyle: 'decimal', pl: 3 }}>
                {objectives.map(objective => (
                  <ListItem key={objective} sx={{ display: 'list-item', pl: 1, ...theme.typography.bodyMedium }}>
                    <Typography variant='bodyMedium'>{objective}</Typography>
                  </ListItem>
                ))}
              </List>
              <Typography variant='bodyMedium'>Approve to continue creating the course.</Typography>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        {approveButton}
      </DialogActions>
    </Dialog>
  );
};
