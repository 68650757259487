import React from 'react';
import { IconButton, Grid, Typography, Chip, Stack, Tab, Tabs } from '@mui/material';
import {
  Close,
  Warning,
  AutoAwesome,
  Done,
  HourglassBottom,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import { StudentWork, OpenEndedMessage } from 'controllers/types';
import { useTranscriptQuery, useLessonInstanceTutorQuery } from 'controllers/react-query';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { CreateMisconceptionLessonButton } from 'components/Teacher/CreateMisconceptionLessonButton';
import { KyronAvatar } from 'components/KyronAvatar/KyronAvatar';
import dayjs from 'dayjs';
import { Row } from 'components/Row/Row';

type Props = {
  lessonInstanceId: number;
  closePanel: () => void;
  rowNumber: number | null;
  studentWorkBreakdown: StudentWork[];
};

enum TabOptions {
  MISCONCEPTIONS,
  TRANSCRIPT,
}

const styles = {
  header: {
    justifyContent: 'space-between',
    marginBottom: 3,
  },
  usageTable: {
    marginBottom: 3,
  },
  tableItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  tabs: {
    marginBottom: 3,
    justifyContent: 'center',
  },
  misconceptionList: {
    marginBottom: 3,
    justifyContent: 'space-between',
    alignItems: 'top',
  },
};

export const StudentPanel = ({ lessonInstanceId, closePanel, rowNumber, studentWorkBreakdown }: Props) => {
  const [row, setRow] = React.useState(rowNumber || 0);
  const studentWork = studentWorkBreakdown[row];
  const [currentTab, setCurrentTab] = React.useState(TabOptions.MISCONCEPTIONS);
  const handleTabChange = (_: unknown, newValue: TabOptions) => {
    setCurrentTab(newValue);
  };
  const { isFetching, isError, data } = useTranscriptQuery(lessonInstanceId);
  const { data: tutorData } = useLessonInstanceTutorQuery(lessonInstanceId);
  const tutorName = tutorData?.display_name || 'Tutor';
  const finished = studentWork?.status === 'Finished';

  const topBar = (
    <Row sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py: 1 }} justifyContent='space-between'>
      <Row gap={1}>
        <IconButton onClick={() => setRow(row - 1)} disabled={row === 0} data-testid='previous-student'>
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={() => setRow(row + 1)}
          disabled={row === studentWorkBreakdown.length - 1}
          data-testid='next-student'
        >
          <KeyboardArrowRight />
        </IconButton>
      </Row>
      <IconButton onClick={closePanel} data-testid='close-panel'>
        <Close />
      </IconButton>
    </Row>
  );

  const header = (
    <Stack direction='column' alignItems='center' gap={1} sx={{ mb: 4 }}>
      <KyronAvatar
        image={studentWork?.avatar_url}
        label={studentWork?.student || ''}
        size={120}
        sx={{ boxShadow: 2 }}
      />
      <Typography variant='headlineSmall'>{studentWork?.student}</Typography>
      <Chip
        icon={
          finished ? (
            <Done fontSize='small' sx={{ color: 'successContainer.dark' }} />
          ) : (
            <HourglassBottom fontSize='small' sx={{ color: 'warningContainer.dark' }} />
          )
        }
        label={finished ? 'Completed' : 'In Progress'}
        data-testid='student-status-chip'
        sx={{
          borderRadius: 16,
          border: '2px solid',
          borderColor: finished ? 'successContainer.dark' : 'warningContainer.dark',
          backgroundColor: finished ? 'successContainer.main' : 'warningContainer.main',
          color: finished ? 'successContainer.contrastText' : 'warningContainer.contrastText',
          typography: 'labelMedium',
          padding: '2px 8px',
        }}
      />
      <Row>
        <Typography variant='bodySmall' sx={{ color: 'text.secondary' }}>
          Started {dayjs(studentWork?.started_at).format('MMM D, YYYY hh:mm A')}
        </Typography>
        {finished && (
          <Typography variant='bodySmall' sx={{ color: 'text.secondary' }}>
            | Completed {dayjs(studentWork?.finished_at).format('MMM D, YYYY hh:mm A')}
          </Typography>
        )}
      </Row>
    </Stack>
  );

  const misconceptions = (
    <Grid container direction='column'>
      <Grid item sx={{ marginBottom: 3 }}>
        <Typography variant='bodySmall'>
          Misconceptions are common mistakes that students make when answering questions in a Kyron lesson. They are
          automatically detected by Kyron&apos;s AI and can be used to help guide your instruction.
        </Typography>
      </Grid>
      {studentWork?.misconceptions.length === 0 && (
        <Grid item>
          <Typography variant='titleSmall'>No misconceptions detected for this student.</Typography>
        </Grid>
      )}
      {studentWork?.misconceptions.map(m => (
        <Grid item container direction='row' key={m.code} sx={styles.misconceptionList} spacing={1}>
          <Grid item xs={1}>
            <Warning color='error' sx={{ marginRight: 1 }} />
          </Grid>
          <Grid item container direction='column' xs={11}>
            <Grid item>
              <Typography variant='titleSmall'>{m.name}</Typography>
            </Grid>
            <Grid item>
              <Typography variant='bodyMedium'>{m.description}</Typography>
            </Grid>
            <Grid item>
              <CreateMisconceptionLessonButton misconception={m} />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  const getSpeaker = (speaker: string) => {
    if (speaker === 'Student') return <Typography variant='labelLarge'>{studentWork?.student}</Typography>;
    return (
      <Stack direction='row' alignItems='center' spacing={1}>
        <Typography variant='labelLarge'>A.I. Assistant</Typography>
        <AutoAwesome fontSize='small' sx={{ color: 'primary.main' }} />
      </Stack>
    );
  };

  const renderStudentResponse = (
    response: { input_type: string; messages: string[] | OpenEndedMessage[] },
    index: number,
  ) => {
    if (response.input_type === 'open_ended') {
      const messages = response.messages as OpenEndedMessage[];
      return messages.map((message, i) => (
        // the data structure does not have a unique id for each message, so we use the index as the key
        // eslint-disable-next-line react/no-array-index-key
        <Grid item container key={i + message.message} sx={{ marginBottom: 2 }} direction='column'>
          <Grid item>{getSpeaker(message.speaker)}</Grid>
          <Grid item>
            <Typography variant='bodyMedium'>{message.message}</Typography>
          </Grid>
        </Grid>
      ));
    }
    const messages = response.messages as string[];
    return (
      <Grid item container sx={{ marginBottom: 2 }} direction='column' key={index}>
        <Grid item>
          <Typography variant='labelLarge'>{studentWork?.student}</Typography>
        </Grid>
        <Grid item>
          <Typography variant='bodyMedium'>{messages[0]}</Typography>
        </Grid>
      </Grid>
    );
  };

  const transcript = (
    <>
      {isFetching && <LoadingIndicator data-testid='loading-indicator' />}
      {isError && <Typography variant='titleSmall'>Error loading transcript.</Typography>}
      {data && (
        <Grid container direction='column' spacing={1}>
          {data.transcript.map((segment, index) => {
            if ('teacher_instructions' in segment) {
              return (
                // the data structure does not have a unique id for each message, so we use the index as the key
                // eslint-disable-next-line react/no-array-index-key
                <Grid item container key={index} sx={{ marginBottom: 2 }} direction='column'>
                  <Grid item>
                    <Typography variant='labelLarge'>{tutorName}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='bodyMedium'>{segment.teacher_instructions}</Typography>
                  </Grid>
                </Grid>
              );
            }
            return renderStudentResponse(segment.student_response, index);
          })}
        </Grid>
      )}
    </>
  );

  const misconceptionsLabel =
    studentWork?.misconceptions.length === 1
      ? '1 Misconception'
      : `${studentWork?.misconceptions.length} Misconceptions`;

  return (
    <Stack>
      {topBar}
      <Stack sx={{ padding: 3 }}>
        {!studentWork && <Typography variant='titleSmall'>No student work found.</Typography>}
        {header}
        <Tabs value={currentTab} onChange={handleTabChange} sx={styles.tabs} variant='fullWidth'>
          <Tab label={misconceptionsLabel} value={TabOptions.MISCONCEPTIONS} />
          <Tab label='Transcript' value={TabOptions.TRANSCRIPT} data-testid='transcript-tab' />
        </Tabs>
        {currentTab === TabOptions.MISCONCEPTIONS && misconceptions}
        {currentTab === TabOptions.TRANSCRIPT && transcript}
      </Stack>
    </Stack>
  );
};
