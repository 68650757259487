import React from 'react';
import {
  AdminOrganizations,
  loader as adminOrganizationsLoader,
} from 'components/admin/AdminOrganizations/AdminOrganizations';
import { AdminUsers, loader as adminUsersLoader } from 'components/admin/AdminUsers/AdminUsers';
import { AdminLayout } from 'components/admin/AdminLayout/AdminLayout';
import { CompletePayment } from 'components/Payments/CompletePayment';
import { PaymentSubscriptions } from 'components/Payments/PaymentSubscriptions';
import { LessonInstanceReport } from 'components/Reports/LessonInstanceReport';
import { TrainingLogs } from 'components/Trainings/TrainingLogs';
import { ProtectedRoute } from 'components/utils/ProtectedRoute';
import { RouteObject, Navigate } from 'react-router-dom';
import { User } from 'controllers/types';

export const adminRoutes = (user: User | null): RouteObject[] => [
  {
    path: 'admin',
    element: <ProtectedRoute user={user} />,
    children: [
      {
        element: <AdminLayout />,
        children: [
          {
            index: true,
            element: <Navigate to='/admin/users' replace />,
          },
          {
            path: 'reports',
            element: <LessonInstanceReport />,
          },
          {
            path: 'training',
            element: <TrainingLogs />,
          },
          {
            path: 'users',
            element: <AdminUsers />,
            loader: adminUsersLoader,
          },
          {
            path: 'organizations',
            element: <AdminOrganizations />,
            loader: adminOrganizationsLoader,
          },
          {
            path: 'payment_subscriptions',
            element: <PaymentSubscriptions />,
          },
          {
            path: 'complete_payment/:sessionId',
            element: <CompletePayment />,
          },
        ],
      },
    ],
  },
];
