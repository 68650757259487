import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useUpdateLesson } from 'controllers/react-query';
import { useSettingsTitle, useLessonSettingsDialogContext } from '../LessonSettingsDialog/LessonSettingsDialog';
import { TypographyWithEllipsis } from '../../TypographyWithEllipsis/TypographyWithEllipsis';
import defaultVideoBackgroundImage from '../../../../assets/images/DefaultVideoBackgroundImage.png';
import { FileUploader, UploadButton } from '../../Uploaders/FileUploader';
import { RemoveFileButton } from '../../Uploaders/RemoveFileButton';
import { ActiveStorageBlob } from '../../Uploaders/types';
import { Lesson, SegmentMedia } from '../../../controllers/types';
import { useUserContext } from '../../UserContext';
import { KyronEvents } from '../../utils/KyronEvents';

export function Branding() {
  useSettingsTitle('Branding');
  const { user } = useUserContext();
  const { lesson } = useLessonSettingsDialogContext();
  const { mutateAsync: updateLesson } = useUpdateLesson();

  const [existingImageFile, setExistingImageFile] = useState<ActiveStorageBlob | null>(null);
  const [inProgress, setInProgress] = useState(false);

  useEffect(updateExistingFile, [lesson]);
  function updateExistingFile() {
    if (lesson?.custom_video_background_image_url) {
      setExistingImageFile({
        url: lesson.custom_video_background_image_url,
        media_type: SegmentMedia.USER_IMAGE_WITH_AUDIO,
      });
    } else {
      setExistingImageFile(null);
    }
  }

  function goBackToDefaultImage() {
    if (lesson) lesson.custom_video_background_image_url = null;
    updateLesson({
      lessonId: lesson?.id || 0,
      payload: { ...lesson },
    })
      .then(() => {
        setExistingImageFile(null);
      })
      .catch(e => {
        enqueueSnackbar(`Something went wrong when removing file. ${e}`, { variant: 'error' });
      });
  }

  function attachImageToLesson(blobs: ActiveStorageBlob[]) {
    const blob = blobs[0];
    if (blob.signed_id) {
      setInProgress(true); // processing starts here
      return updateLesson({
        lessonId: lesson?.id || 0,
        payload: { ...lesson, image_signed_id: blob.signed_id },
      })
        .then(({ resource: updatedLesson }: { resource: Lesson }) => {
          setExistingImageFile({
            url: updatedLesson.custom_video_background_image_url || undefined,
            media_type: SegmentMedia.USER_IMAGE_WITH_AUDIO,
          });
          KyronEvents.sendEvent(KyronEvents.names.UPLOAD_BRAND_IMAGE, {
            user_id: user?.id || 'anonymous',
            lesson_id: lesson?.id,
          });
        })
        .catch(e => {
          const errMessage = `Something went wrong when uploading file. ${e}`;
          enqueueSnackbar(errMessage, { variant: 'error' });
          throw e; // to make FileUploader clean the internal file state on error
        })
        .finally(() => {
          setInProgress(false); // processing ends here
        });
    }
  }

  console.debug('Branding: existingImageFile: ', existingImageFile);
  return (
    <Stack spacing={2} sx={{ px: 4, pt: 2 }}>
      <TypographyWithEllipsis variant='labelMedium'>Generated Avatar Slide Background</TypographyWithEllipsis>
      <Typography variant='bodySmall' color='textSecondary'>
        You can provide your own background image when you use generated avatar slides. Only PNG, JPG, and JPEG files
        with 1920x1080 resolution are supported.
      </Typography>
      {lesson && (
        <Box
          sx={{
            position: 'relative', // Set the container to relative
            display: 'inline-block', // This ensures that the button is positioned based on the image size
            width: '320px',
            height: '180px',
            padding: '8px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            borderRadius: '6px',
            border: '1px solid',
          }}
        >
          <img
            style={{
              display: 'block', // Removes any gap beneath the image in inline-block
              width: '100%',
              height: '100%',
            }}
            src={lesson.custom_video_background_image_url || defaultVideoBackgroundImage}
            alt={lesson.custom_video_background_image_url ? 'Custom Image' : 'Default Image'}
          />
          <Box
            sx={{
              position: 'absolute', // Position the button absolutely relative to the container
              top: '50%', // Center vertically
              left: '50%', // Center horizontally
              transform: 'translate(-50%, -50%)', // Adjust the button's position to exactly center it
            }}
          >
            <FileUploader
              fileTypeName='image'
              afterUpload={attachImageToLesson}
              isAttachmentBeingProcessed={inProgress}
              acceptTypes='image/png, image/jpg, image/jpeg'
              existingFile={existingImageFile}
            >
              <UploadButton
                sx={{ marginBottom: '5px' }}
                variant='raised'
                buttonLabel={existingImageFile ? 'Replace image' : 'Upload image'}
              />
              <RemoveFileButton variant='raised' buttonLabel='Remove image' onRemove={goBackToDefaultImage} />
            </FileUploader>
          </Box>
        </Box>
      )}
    </Stack>
  );
}
