import React from 'react';
import { Outlet } from 'react-router-dom';
import { HelpButton } from 'components/HelpButton/HelpButton';
import { Box, GlobalStyles } from '@mui/material';
import { BackButton } from 'components/BackButton/BackButton';
import { AppBar } from 'components/AppBar/AppBar';

export function CourseCreationLayout() {
  return (
    <>
      <GlobalStyles styles={theme => ({ body: { backgroundColor: theme.palette.background.paper } })} />
      <Box sx={{ width: '100vw', height: '100vh' }}>
        <AppBar
          showLogo={false}
          navButton={<BackButton fallback='/studio/courses' />}
          mainToolBarSx={{ bgcolor: 'background.paper' }}
        />
        <Outlet />
      </Box>
      <HelpButton />
    </>
  );
}
