import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { Members } from '../../Studio';
import { IntegrationsPage } from '../../Studio/Settings/IntegrationsPage';
import { BillingSettings } from '../../Studio/Settings/BillingSettings';
import { Settings } from '../../Studio/Settings/Settings';

export const settingsRoutes: RouteObject[] = [
  {
    path: 'members',
    element: <Navigate to='/studio/settings/members' replace />,
  },
  {
    path: 'settings/members',
    element: <Members />,
  },
  {
    path: 'settings',
    element: <Settings />,
  },
  {
    path: 'settings/integrations',
    element: <IntegrationsPage />,
  },
  {
    path: 'settings/billing',
    element: <BillingSettings />,
  },
];
