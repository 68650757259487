import React from 'react';
import { Typography, Link } from '@mui/material';
import { useGetLessonCollections } from 'controllers/react-query/lessonCollectionHooks';

type CourseGeneratingMessageProps = {
  title: string;
  type?: 'course' | 'practice';
};

export const CourseGeneratingMessage = ({ title, type = 'course' }: CourseGeneratingMessageProps) => {
  const { data: lessonCollections } = useGetLessonCollections({ featured: true });
  const previewCourse = lessonCollections?.[0];
  const route = `/courses/${previewCourse?.id}`;
  const createdItem = type === 'course' ? `Your course, ${title}, is` : 'Your practice problems are';

  return (
    <Typography>
      {createdItem} in the works! It&apos;ll take a few minutes to create. In the meantime, why not grab a coffee, do a
      little dance, or&nbsp;
      <Link href={route} target='_blank'>
        check out one of our other courses
      </Link>
      . We&apos;ll send you an email when this one is ready to edit.
    </Typography>
  );
};
