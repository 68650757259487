import { BasicLessonPayload } from 'controllers/types';

export const SAMPLE_COURSES: BasicLessonPayload[] = [
  {
    lessonTitle: 'The Mechanics of Bird Flight',
    audience: 'High school seniors',
    lessonObjective: `Students will be able to describe the four fundamental forces involved in bird flight, analyze how wing shape and structure contribute to flight capability, and compare different types of bird flight patterns`,
  },
  {
    lessonTitle: 'The Mystery of Black Holes',
    audience: 'High school students with an interest in astrophysics',
    lessonObjective: `Students will be able to describe the formation process of black holes, explain the concept of the event horizon, and evaluate how black holes impact their surrounding environment`,
  },
  {
    lessonTitle: 'Origins of Human Language',
    audience: 'College students studying linguistics',
    lessonObjective: `Students will be able to identify theories about the evolution of human language, examine evidence supporting the connection between language and cognitive development, and discuss how language diversity reflects human culture and history`,
  },
  {
    lessonTitle: 'How Solar Panels Convert Sunlight to Electricity',
    audience: 'High school physics students',
    lessonObjective: `Students will be able to explain the basic principles of photovoltaics, identify key components of a solar panel system, and analyze the advantages and limitations of solar energy`,
  },
  {
    lessonTitle: 'Understanding Time Travel Theory',
    audience: 'High school students interested in science fiction and theoretical physics',
    lessonObjective: `Students will be able to explore the concept of time travel through the lens of relativity, discuss potential paradoxes associated with time travel, and evaluate the feasibility of time travel based on current scientific understanding`,
  },
  {
    lessonTitle: 'How Plants Use Photosynthesis',
    audience: 'Middle school science students',
    lessonObjective: `Students will be able to describe the process of photosynthesis, identify the role of chlorophyll in capturing sunlight, and explain the importance of photosynthesis in the global ecosystem`,
  },
  {
    lessonTitle: 'Why Volcanoes Erupt',
    audience: 'Middle school students interested in Earth science',
    lessonObjective: `Students will be able to understand the geological conditions that lead to volcanic eruptions, describe the structure and components of a volcano, and discuss the impact of volcanic eruptions on the environment and human societies`,
  },
  {
    lessonTitle: 'The Impact of Artificial Intelligence on Society',
    audience: 'College students studying technology and ethics',
    lessonObjective: `Students will be able to define artificial intelligence, analyze ethical considerations associated with AI implementation, and evaluate the societal benefits and challenges posed by AI advancements`,
  },
  {
    lessonTitle: 'Why Leaves Change Colors in Autumn',
    audience: 'Middle school students',
    lessonObjective: `Students will be able to explain the biological process behind leaf color change, identify environmental factors influencing this change, and discuss the ecological significance of seasonal changes in trees`,
  },
  {
    lessonTitle: 'The Importance of The Higgs Boson',
    audience: 'High school physics students',
    lessonObjective: `Students will be able to understand the role of the Higgs boson in the Standard Model of particle physics, explain how the discovery of the Higgs boson was achieved, and discuss its implications for understanding the universe`,
  },
  {
    lessonTitle: 'How the Human Brain Develops',
    audience: 'High school biology students',
    lessonObjective: `Students will be able to outline the stages of brain development, explain the role of genetics and environment, and identify key milestones in neural development and their importance`,
  },
  {
    lessonTitle: 'The Concept of Infinity in Mathematics',
    audience: 'High school math students',
    lessonObjective: `Students will be able to define the concept of infinity, explore its use in calculus and set theory, and analyze the paradoxes associated with infinity in mathematical theory`,
  },
  {
    lessonTitle: 'The Science Behind Climate Change',
    audience: 'High school students interested in environmental science',
    lessonObjective: `Students will be able to explain the greenhouse effect, identify human activities contributing to global warming, and discuss the potential impacts of climate change on ecosystems and societies`,
  },
  {
    lessonTitle: 'The History of The Universe',
    audience: 'College students studying astronomy or cosmology',
    lessonObjective: `Students will be able to outline the timeline of the universe from the Big Bang to the present, explain key events such as the formation of galaxies and stars, and analyze evidence supporting current models of the universe's history`,
  },
  {
    lessonTitle: 'What Makes Bridges Stable?',
    audience: 'High school physics and engineering students',
    lessonObjective: `Students will be able to identify the forces acting on a bridge, explain the importance of materials and design in bridge construction, and analyze case studies of famous bridges to understand principles of stability`,
  },
  {
    lessonTitle: 'Why Do We Have Dreams?',
    audience: 'High school psychology students',
    lessonObjective: `Students will be able to explore theories about the purpose of dreaming, discuss the stages of sleep and their relationship to dreaming, and analyze the impact of dreams on mental and emotional health`,
  },
  {
    lessonTitle: 'What Fuels Hurricanes?',
    audience: 'Middle school Earth science students',
    lessonObjective: `Students will be able to describe the conditions necessary for hurricane formation, explain how hurricanes gain energy from warm ocean waters, and discuss their impact on human and natural systems`,
  },
  {
    lessonTitle: 'The Science of Baking: Why Bread Rises',
    audience: 'Middle school science students',
    lessonObjective: `Students will be able to explain the role of yeast in the bread-making process, identify the chemical reactions involved in dough rising, and discuss the importance of temperature and timing in baking`,
  },
  {
    lessonTitle: 'How Does GPS Determine Location?',
    audience: 'High school students interested in technology and navigation',
    lessonObjective: `Students will be able to explain the concept of triangulation, identify the role of satellites in GPS technology, and analyze the accuracy and limitations of GPS systems`,
  },
  {
    lessonTitle: 'The Chemistry of Smell',
    audience: 'High school chemistry students',
    lessonObjective: `Students will be able to explain how molecules interact with receptors to produce the sensation of smell, identify the role of the olfactory system, and discuss the chemical composition of common scents`,
  },
  {
    lessonTitle: 'What Triggers Earthquakes?',
    audience: 'Middle school students studying geology',
    lessonObjective: `Students will be able to describe the causes of earthquakes, identify different types of seismic waves, and analyze the impact of earthquakes on human structures and natural landscapes`,
  },
  {
    lessonTitle: 'How Are Fossils Formed?',
    audience: 'Middle school students studying paleontology',
    lessonObjective: `Students will be able to explain the process of fossilization, identify the conditions required for fossil formation, and discuss the significance of fossils in understanding Earth's history`,
  },
  {
    lessonTitle: 'Exploring Dark Matter',
    audience: 'High school physics students',
    lessonObjective: `Students will be able to define dark matter, analyze evidence supporting its existence, and discuss the challenges and methods used in its study`,
  },
  {
    lessonTitle: 'How Bees Communicate',
    audience: 'Middle school biology students',
    lessonObjective: `Students will be able to describe the methods bees use to communicate, explain the significance of the waggle dance, and analyze the importance of communication within a bee colony`,
  },
  {
    lessonTitle: 'The Neuroscience of Memory',
    audience: 'High school psychology students',
    lessonObjective: `Students will be able to explain how memories are formed and stored, identify factors affecting memory retention, and analyze the differences between short-term and long-term memory`,
  },
  {
    lessonTitle: 'Explaining the Doppler Effect',
    audience: 'High school physics students',
    lessonObjective: `Students will be able to define the Doppler Effect, describe its applications in real-world contexts like radar and astronomy, and analyze its impact on sound and light waves`,
  },
  {
    lessonTitle: 'How Lasers Cut Materials',
    audience: 'High school physics students',
    lessonObjective: `Students will be able to explain the principles of laser operation, identify the properties of materials suitable for laser cutting, and discuss the applications of lasers in industries and medicine`,
  },
  {
    lessonTitle: 'What is String Theory?',
    audience: 'High school physics students interested in advanced topics',
    lessonObjective: `Students will be able to define string theory, discuss its significance in unifying fundamental forces, and analyze the challenges and criticisms associated with it`,
  },
  {
    lessonTitle: 'How Do Muscles Grow?',
    audience: 'High school biology students',
    lessonObjective: `Students will be able to describe the biological process of muscle growth, explain the role of exercise and nutrition, and analyze the impact of hormones on muscle development`,
  },
  {
    lessonTitle: 'The Impact of Social Media on Mental Health',
    audience: 'High school students studying psychology and media',
    lessonObjective: `Students will be able to analyze the effects of social media on mental health, identify psychological mechanisms driving social media use, and discuss strategies for maintaining healthy habits`,
  },
  {
    lessonTitle: 'Why Do Stars Twinkle?',
    audience: 'Middle school students interested in astronomy',
    lessonObjective: `Students will be able to explain why stars appear to twinkle due to atmospheric turbulence, analyze how light refraction affects starlight, and describe techniques astronomers use to reduce this effect in observations`,
  },
  {
    lessonTitle: 'Why Is Mars Red?',
    audience: 'Middle school students studying planetary science',
    lessonObjective: `Students will be able to describe the chemical processes that cause Mars to appear red, explain the role of iron oxide in the planet's surface, and discuss the implications of Mars' composition for its history and exploration`,
  },
  {
    lessonTitle: 'The Art and Science of Tattooing',
    audience: 'High school students studying art and biology',
    lessonObjective: `Students will be able to explain how tattoos are applied to the skin, describe the layers of skin involved, and discuss the cultural significance and safety considerations of tattooing`,
  },
  {
    lessonTitle: 'The Mechanisms of Human Sleep Cycles',
    audience: 'High school psychology students',
    lessonObjective: `Students will be able to describe the stages of sleep, explain how circadian rhythms regulate sleep patterns, and analyze the effects of sleep on physical and mental health`,
  },
  {
    lessonTitle: 'Decoding the Human Genome',
    audience: 'High school biology students',
    lessonObjective: `Students will be able to describe the structure of DNA, explain the purpose and accomplishments of the Human Genome Project, and analyze the ethical implications of genome editing technologies`,
  },
  {
    lessonTitle: 'The Theory of Plate Tectonics',
    audience: 'Middle school students studying Earth science',
    lessonObjective: `Students will be able to explain the theory of plate tectonics, describe the types of plate boundaries, and analyze the impact of tectonic activity on Earth's surface and human life`,
  },
  {
    lessonTitle: 'What Prevents Aircraft from Falling?',
    audience: 'Middle school students interested in physics and engineering',
    lessonObjective: `Students will be able to describe the principles of aerodynamics, explain how lift is generated by airplane wings, and analyze how thrust, drag, and weight interact to keep an aircraft in the air`,
  },
  {
    lessonTitle: 'The Physics of Sound Waves',
    audience: 'High school physics students',
    lessonObjective: `Students will be able to describe the properties of sound waves, explain how sound travels through different mediums, and analyze how frequency and amplitude affect the perception of sound`,
  },
  {
    lessonTitle: 'How Do Plants Communicate?',
    audience: 'Middle school biology students',
    lessonObjective: `Students will be able to describe the chemical signals plants use to communicate, explain the role of mycorrhizal networks, and analyze how plant communication impacts ecosystems`,
  },
  {
    lessonTitle: 'Why Nations Go to War',
    audience: 'High school students studying history and political science',
    lessonObjective: `Students will be able to describe the historical and political causes of war, analyze key conflicts in history, and discuss the impact of war on societies and international relations`,
  },
  {
    lessonTitle: 'The Mathematics of Chaos Theory',
    audience: 'High school students studying advanced mathematics',
    lessonObjective: `Students will be able to define chaos theory, describe its significance in understanding complex systems, and analyze real-world applications like weather prediction and population dynamics`,
  },
  {
    lessonTitle: 'The Evolution of Computers',
    audience: 'Middle school students studying technology',
    lessonObjective: `Students will be able to describe the history of computer development, explain the impact of key technological advances, and discuss how computers have transformed society`,
  },
  {
    lessonTitle: 'How is Coffee Decaffeinated?',
    audience: 'High school chemistry students',
    lessonObjective: `Students will be able to explain the chemical processes used to decaffeinate coffee, compare different decaffeination methods, and analyze how these processes affect the flavor and composition of coffee`,
  },
  {
    lessonTitle: 'The Secrets of Coral Reefs',
    audience: 'Middle school students studying marine biology',
    lessonObjective: `Students will be able to describe the biodiversity of coral reefs, explain their ecological importance, and analyze the threats to coral reefs and conservation efforts to protect them`,
  },
  {
    lessonTitle: 'How Robots Learn and Adapt',
    audience: 'High school students interested in robotics and AI',
    lessonObjective: `Students will be able to describe how machine learning enables robots to adapt, explain the role of sensors and algorithms, and analyze the ethical implications of adaptive robotics`,
  },
  {
    lessonTitle: 'The Physics of Basketball',
    audience: 'Middle school physics students',
    lessonObjective: `Students will be able to describe the forces acting on a basketball, explain how projectile motion determines the trajectory of a shot, and analyze the role of energy transfer in dribbling and shooting`,
  },
  {
    lessonTitle: 'Why Do Cats Purr?',
    audience: 'Middle school students interested in biology',
    lessonObjective: `Students will be able to describe the physiological mechanism behind purring, explain the reasons cats purr, and analyze the evolutionary advantages of this behavior`,
  },
  {
    lessonTitle: 'How Does 3D Printing Work?',
    audience: 'High school students studying engineering and technology',
    lessonObjective: `Students will be able to describe the basic principles of 3D printing, explain how different materials are used, and analyze the applications of 3D printing in various industries`,
  },
  {
    lessonTitle: 'Cultural Impacts of The Industrial Revolution',
    audience: 'High school students studying history and sociology',
    lessonObjective: `Students will be able to describe the key changes brought by the Industrial Revolution, analyze its impact on social structures and culture, and discuss its long-term effects on modern society`,
  },
  {
    lessonTitle: 'Why Ancient Civilizations Collapsed',
    audience: 'High school students studying history and archaeology',
    lessonObjective: `Students will be able to describe factors that led to the collapse of ancient civilizations, analyze case studies like the Mayans and Romans, and discuss lessons that modern societies can learn from these events`,
  },
];
