import React, { useMemo, useState } from 'react';
import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { List } from '@mui/material';
import { DragHandle, SortableItem } from './components';

type BaseItem = { id: string | number };
type Props<T extends BaseItem> = {
  items: T[];
  onChange(items: T[]): void;
  renderItem(item: T, opts?: { isOverlay?: boolean }): React.ReactNode;
};

export function SortableList<T extends BaseItem>({ items, onChange, renderItem }: Props<T>) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );
  const [activeDraggable, setActiveDraggable] = useState<Active | null>(null);
  const activeItem = useMemo(() => items.find(item => item.id === activeDraggable?.id), [activeDraggable, items]);

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        setActiveDraggable(active);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          onChange(arrayMove(items, activeIndex, overIndex));
        }
      }}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <List sx={{ width: '100%' }} dense>
          {items.map(item => (
            <React.Fragment key={item.id}>{renderItem(item)}</React.Fragment>
          ))}
        </List>
      </SortableContext>

      {/*
        When items are being moved around, the DragOverlay is the element user holds and moves around.
        The element rendered above is the one that shows where the element is going to be dropped at (placeholder).
        We pass down the isOverlay option to tell renderer that this is the overlay element to update styles accordingly.
      */}
      <DragOverlay>{activeItem ? renderItem(activeItem, { isOverlay: true }) : null}</DragOverlay>
    </DndContext>
  );
}

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
