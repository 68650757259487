import { useEffect, useState } from 'react';

import { BackgroundTask } from 'controllers/types';
import consumer from '../../channels/consumer';

// background_tasks with these statuses are monitored through a websocket
const pendingStatusStates = ['enqueued', 'started', 'waiting'];

type ChannelData = { status: BackgroundTask['status']; message: string };

/**
 * @deprecated
 * This hook is deprecated and should not be used in new code. Try using one of our new hooks instead:
 * - `useBackgroundTaskChannel`: works more like this hook to watch a single task
 * - `useLessonBTWatcher`: watches all tasks for a lesson
 * - `useLessonSegmentBTWatcher`: watches all tasks for a lesson segment
 */
export function useProgressBarChannel(task?: BackgroundTask, taskDoneFunction?: (data: BackgroundTask) => void) {
  const [taskData, setTaskData] = useState(task || ({} as BackgroundTask)); // empty object to allow consumer to use dot notation

  // disabling because we don't wanna run this effect on taskData changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setInitialTaskState, [task, task?.id, task?.status]);
  function setInitialTaskState() {
    if (task?.id !== taskData.id || task?.status !== taskData.status) {
      setTaskData(task !== undefined ? task : ({} as BackgroundTask));
    }
  }

  useEffect(() => {
    // background_tasks with these statuses are monitored through a websocket
    if (task !== undefined && pendingStatusStates.some(s => task?.status === s)) {
      const channel = consumer.subscriptions.create(
        {
          channel: 'ProgressBarChannel',
          task_id: task.id,
        },
        {
          received(data: ChannelData) {
            console.debug('useProgressBarChannel received', { taskName: task.name, ...data });
            const updatedTask = { ...taskData, ...data };
            setTaskData(updatedTask);
            if (taskDoneFunction) taskDoneFunction(updatedTask);
          },
        },
      );
      return () => {
        channel.unsubscribe();
        consumer.disconnect();
      };
    }
  }, [task, taskData, taskDoneFunction]);

  return taskData;
}
