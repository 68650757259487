import React, { useMemo, ReactNode } from 'react';
import { Box, Typography, Alert, CircularProgress, AlertTitle } from '@mui/material/';
import { LessonSegment } from 'controllers/types';
import { useLessonSegmentBTWatcher } from '../BackgroundTasks/backgroundTaskWatchers';

type SegmentVideoAlertProps = {
  severity: 'error' | 'warning';
  title: string;
  message: string;
  icon?: ReactNode;
};

export const SegmentVideoAlert = ({ severity, title, message, icon }: SegmentVideoAlertProps) => (
  <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
    <Alert severity={severity} icon={icon}>
      <AlertTitle>{title}</AlertTitle>
      <Typography variant='bodyMedium'>{message}</Typography>
    </Alert>
  </Box>
);

export const useSegmentVideoAlertProps = (lessonSegment: LessonSegment | null): SegmentVideoAlertProps | null => {
  const { isVideoGenerationInProgress } = useLessonSegmentBTWatcher(lessonSegment?.id);

  return useMemo(() => {
    if (!lessonSegment) return null;

    const isMissing = lessonSegment.video_url === null;
    const isProcessing = lessonSegment.video_url?.includes('status=processing');
    const isError = lessonSegment.video_url?.includes('status=error');

    if (isMissing) {
      return {
        severity: 'warning',
        title: 'Missing Video',
        message: isVideoGenerationInProgress
          ? 'Your videos are being generated. This may take a few minutes.'
          : 'Please generate or upload missing videos for this course.',
        icon: isVideoGenerationInProgress ? <CircularProgress size='1em' /> : null,
      };
    }

    if (isProcessing) {
      return {
        severity: 'warning',
        title: 'Video is processing',
        message:
          'This video is still being processed. Try playing again later. This should not take more than a few minutes. If that is the case, processing of this video might have failed unexpectedly.',
        icon: <CircularProgress size='1em' />,
      };
    }
    if (isError) {
      return {
        severity: 'error',
        title: 'Corrupted video',
        message: 'This video had an error during normalization process and is not suitable for playing.',
        icon: null,
      };
    }

    return null;
  }, [isVideoGenerationInProgress, lessonSegment]);
};
