import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, Button, Card, CardContent, Skeleton, Stack } from '@mui/material';
import { MinimalSegment, LessonSection } from 'controllers/types';
import { SEGMENT_TYPE_ICON } from 'enums/segmentTypeIcon';
import { SEGMENT_TYPE_LABEL } from 'enums/segmentTypeLabel';
import { useDraggableList, getDraggableStyles, getDraggableProps } from '../components/useDraggableList';
import { useUpdateSectionOrSegmentPosition } from '../../../controllers/react-query/lessonSectionHooks';

export function SegmentList({ section }: { section: LessonSection }) {
  const { enqueueSnackbar } = useSnackbar();
  const [draggableSegments, setDraggableSegments] = useState<MinimalSegment[]>(section.segments);
  const { mutate: repositionSegment, isPending } = useUpdateSectionOrSegmentPosition();

  const draggableListResults = useDraggableList<MinimalSegment>(
    setDraggableSegments,
    (_item, _newIndex, reorderedList) => {
      repositionSegment(
        {
          lessonSectionId: section.id,
          lessonId: section.lesson_id,
          payload: { lessonSection: { segments: reorderedList } },
        },
        {
          onError: error => {
            const errorTitle = `Failed to reorder segments`;
            console.error(errorTitle, section.id, error.message);
            enqueueSnackbar(errorTitle, { variant: 'error' });
          },
        },
      );
    },
  );

  useEffect(() => {
    setDraggableSegments(section.segments); // reset segments if they change
  }, [section.segments]);

  return (
    <Stack data-testid='droppable-container'>
      {isPending ? (
        <SectionLoadingIndicator />
      ) : (
        draggableSegments.map((segment, segmentIdx) => (
          <Box
            key={segment.id}
            data-testid='draggable-segment'
            color='text.secondary'
            sx={{
              ...getDraggableStyles(segmentIdx, draggableListResults),
            }}
            {...getDraggableProps(segmentIdx, draggableListResults)}
          >
            <Button
              component={NavLink}
              preventScrollReset
              to={`/studio/courses/${section.lesson_id}/segments/${segment.id}`}
              variant='text'
              size='small'
              data-testid='draggable-segment'
              startIcon={SEGMENT_TYPE_ICON[segment.label]}
              sx={{
                p: '4px 10px',
                typography: 'bodyMedium',
                color: 'inherit',
                '&.active': { color: 'primary.main' },
                ...getDraggableStyles(segmentIdx, draggableListResults),
              }}
            >
              {SEGMENT_TYPE_LABEL[segment.label]}
            </Button>
          </Box>
        ))
      )}
    </Stack>
  );
}

function SectionLoadingIndicator() {
  return (
    <Card sx={{ bgcolor: 'inherit' }} data-testid='loading-indicator'>
      <CardContent>
        <Stack gap={1} sx={{ opacity: 0.8 }}>
          <Stack gap={2}>
            <Skeleton variant='rectangular' height={18} />
            <Skeleton variant='rectangular' height={18} />
            <Skeleton variant='rectangular' height={18} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
