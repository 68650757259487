import { Box, Typography, Stack } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React from 'react';
import { KyronTooltip } from '../../../../KyronTooltip';

const Bold = ({ children }: { children: React.ReactNode }) => (
  <Typography component='span' sx={{ fontWeight: 'bold' }}>
    {children}
  </Typography>
);

export const AuxContentIngestionDetailTooltip = () => (
  <KyronTooltip
    htmlContent
    placement='top'
    title={
      <Box>
        <Typography variant='labelMedium'>Do I need to upload materials?</Typography>
        <Typography variant='bodySmall'>
          No. Simply explaining your audience and objectives is sufficient for generating quality courses. However,
          uploading materials that reflect your style and approach can enhance the course&apos;s relevance.
        </Typography>

        <Typography variant='labelMedium'>What happens when I upload materials?</Typography>
        <Typography variant='bodySmall'>
          Our course generator will generate lessons that are more aligned with your teaching style, incorporating
          specific vocabulary and examples from your materials. Without uploaded content, our course generator relies on
          general subject knowledge to determine vocabulary, examples, and teaching approaches.
        </Typography>

        <Typography variant='labelMedium'>How do we handle uploaded content?</Typography>
        <Typography variant='bodySmall'>
          We analyze your materials&apos; terminology, specific content, and teaching approaches to create courses
          tailored to your materials&apos; context. Your materials are only used to customize course generation.
        </Typography>

        <Typography variant='labelMedium'>Recommended Materials</Typography>
        <Typography variant='bodySmall'>
          To improve course relevance, consider uploading text-based teaching materials that are relevant to the course
          objectives. The best type of files to upload are:
          <ul>
            <li>Educational or professional development textbook chapters</li>
            <li>Lecture notes</li>
            <li>Summary sheets</li>
            <li>Handouts</li>
            <li>Reading passages</li>
            <li>Study guides</li>
            <li>Case studies</li>
          </ul>
          (Files should be in either PDF, DOCX, or TXT format.)
        </Typography>
        <Typography variant='labelMedium'>Important Guidelines:</Typography>
        <Typography variant='bodySmall'>
          <ul>
            <li>
              <Typography variant='labelMedium'>
                Upload only materials that directly relate to your course objectives
              </Typography>{' '}
              Upload only relevant content - irrelevant materials may reduce the quality of generated courses.
            </li>
            <li>
              <Typography variant='labelMedium'>Use primarily text-based materials</Typography> Our system cannot yet
              process image content effectively. Ensure your key information is in text format.
            </li>
          </ul>
        </Typography>
      </Box>
    }
  >
    <HelpOutlineIcon />
  </KyronTooltip>
);

export const SlideImageIngestionDetailTooltip = () => (
  <KyronTooltip
    htmlContent
    placement='top'
    title={
      <Box>
        <Typography variant='labelMedium'>Do I need to upload images?</Typography>
        <Typography variant='bodySmall'>
          No. Simply explaining your audience and objectives is sufficient for generating quality courses.
        </Typography>

        <Typography variant='labelMedium'>What happens when I upload images?</Typography>
        <Typography variant='bodySmall'>
          Our course generator will look at and attempt to understand the content of your images. If it detects than an
          image is appropriate to what is being taught in a specific lecture video, the image will be added to that
          video.
        </Typography>
      </Box>
    }
  >
    <HelpOutlineIcon />
  </KyronTooltip>
);

export const TitleTooltip = () => (
  <KyronTooltip
    htmlContent
    placement='top'
    title={
      <Stack gap={1}>
        <Typography variant='labelMedium'>Title</Typography>
        <Typography variant='bodySmall'>
          This is what you&apos;d like to <Bold>call your lesson</Bold>. Choose a title that is clear and meaningful to
          you or your learners.
        </Typography>

        <Typography variant='bodySmall'>
          <Bold>Note: </Bold>Titles are for your reference only and will{' '}
          <Bold>not influence the AI-generated content.</Bold>
        </Typography>
      </Stack>
    }
  >
    <HelpOutlineIcon />
  </KyronTooltip>
);

export const AudienceTooltip = () => (
  <KyronTooltip
    htmlContent
    placement='top'
    title={
      <Stack gap={1}>
        <Typography variant='labelMedium'>Audience</Typography>
        <Typography variant='bodySmall'>
          The <Bold>audience</Bold> describes the group of learners for whom this lesson is designed.
        </Typography>

        <Typography variant='bodySmall'>
          The information you provide will influence <Bold>how the content is delivered.</Bold> This includes the
          language, sentence structure, reading level, and complexity of the material.
        </Typography>

        <Typography variant='bodySmall'>
          <strong>Tip:</strong> Be specific to help us tailor the content effectively. For example:
          <ul>
            <li>“High school students preparing for AP Biology.”</li>
            <li>“College students new to psychology concepts.”</li>
            <li>“Adult learners needing beginner-friendly coding instruction.”</li>
          </ul>
        </Typography>
      </Stack>
    }
  >
    <HelpOutlineIcon />
  </KyronTooltip>
);

export const LearningObjectivesTooltip = () => (
  <KyronTooltip
    htmlContent
    placement='top'
    title={
      <Stack gap={1}>
        <Typography variant='labelMedium'>Learning Objectives</Typography>
        <Typography variant='bodySmall'>
          A <Bold>learning objective</Bold> describes what learners will be able to <Bold>do, know, or understand</Bold>{' '}
          after completing this lesson.
        </Typography>

        <Typography variant='bodySmall'>
          The learning objectives you provide will be used to:
          <ul>
            <li>
              <Bold>Pull the correct content</Bold> that aligns with the lesson&apos;s goals.
            </li>
            <li>
              <Bold>Organize</Bold> the content in the right order to ensure a logical flow of learning.
            </li>
          </ul>
        </Typography>

        <Typography variant='bodySmall'>
          <Bold>Tip:</Bold> Include <Bold>one clear learning objective for every 30 minutes of content.</Bold>
        </Typography>
        <Typography variant='bodySmall'>
          <Bold>Example:</Bold>
          <ul>
            <li>
              “Learners will be able to <Bold>identify</Bold> the key components of X.”
            </li>
            <li>
              “By the end of this lesson, learners will be able to <Bold>analyze</Bold> Y and draw conclusions.”
            </li>
          </ul>
        </Typography>
      </Stack>
    }
  >
    <HelpOutlineIcon />
  </KyronTooltip>
);
