import React from 'react';
import { User } from 'controllers/types';
import { RouteObject } from 'react-router-dom';
import { App } from 'components/App';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import { MarketingHome } from 'components/MarketingHome/MarketingHome';
import { marketingRoutes } from './MarketingRoutes';
import { studioRoutes } from './StudioRoutes';
import { adminRoutes } from './AdminRoutes';
import { lessonRoutes } from './LessonRoutes';
import { k12Routes } from './k12Routes';

export function getRoutes(user: User | null): RouteObject[] {
  return [
    {
      path: '/',
      element: <App />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <MarketingHome />,
        },
        ...marketingRoutes,
        ...k12Routes(user),
        ...lessonRoutes,
        ...studioRoutes(user),
        ...adminRoutes(user),
      ],
    },
  ];
}
