import React from 'react';
import { Editor } from 'components/Studio';
import { Branding } from 'components/Studio/components/Branding';
import { LessonContext } from 'components/Studio/components/LessonContext';
import { MisconceptionDialog } from 'components/Studio/components/MisconceptionDialog';
import { CourseAnalytics } from 'components/Studio/Editor/CourseAnalytics';
import { SegmentDetail } from 'components/Studio/Editor/SegmentDetail';
import { GeneralSettings } from 'components/Studio/LessonSettingsDialog/GeneralSettings';
import { LanguageAndVoiceSettings } from 'components/Studio/LessonSettingsDialog/LanguageAndVoiceSettings';
import { LessonSettingsDialog } from 'components/Studio/LessonSettingsDialog/LessonSettingsDialog';
import { useLessonSectionsQuery } from 'controllers/react-query';
import { useParams, Navigate, NavigateProps, useLocation, RouteObject } from 'react-router-dom';

const RedirectToFirstSegment = () => {
  const { lessonId } = useParams();
  const { data } = useLessonSectionsQuery(Number(lessonId));
  const { lesson_sections: lessonSections = [] } = data || {};
  const firstSegmentId = lessonSections[0]?.segments[0]?.id;
  return firstSegmentId ? <Navigate to={`segments/${firstSegmentId}`} replace /> : <SegmentDetail />;
};

/*
  This wrapper makes it possible to always go back to whatever was the route when the settings dialog is opened.
  So if you're at studio/123/segments/123 and go to settings, the route chages to studio/123/settings/general.
  When the dialog is closed we want the route to go back to studio/123/segments/123. We use the previousLocation in the state to achieve this.
*/
const NavigateWithPreviousLocationState = ({ state, ...props }: NavigateProps) => {
  const location = useLocation();
  return <Navigate {...props} state={{ previousLocation: location?.state?.previousLocation, ...state }} />;
};

export const editorRoutes: RouteObject[] = [
  {
    path: 'studio/courses/:lessonId/*',
    children: [
      {
        path: '*',
        element: <Editor />,
        children: [
          {
            index: true,
            element: <RedirectToFirstSegment />,
          },
          {
            path: 'segments',
            element: <Navigate to='../' replace />,
          },
          {
            path: 'segments/:segmentId',
            element: <SegmentDetail />,
          },
          {
            path: 'settings',
            element: <LessonSettingsDialog />,
            children: [
              {
                index: true,
                element: <NavigateWithPreviousLocationState to='general' replace />,
              },
              {
                path: 'general',
                element: <GeneralSettings />,
              },
              {
                path: 'language-and-voice',
                element: <LanguageAndVoiceSettings />,
              },
              {
                path: 'misconceptions',
                element: <MisconceptionDialog />,
              },
              {
                path: 'course-context',
                element: <LessonContext />,
              },
              {
                path: 'branding',
                element: <Branding />,
              },
            ],
          },
        ],
      },
      {
        path: 'analytics',
        element: <CourseAnalytics />,
      },
    ],
  },
];
