import React from 'react';
import { Box, Stack, TextFieldProps, Typography, TextField } from '@mui/material';
import { InfoBox } from 'components/InfoBox';

type SegmentFieldProps = {
  title: string;
  info: string;
  tooltipNote?: string | React.ReactNode;
  value?: string | null;
  warning?: string | null;
} & TextFieldProps;

export function SegmentField({ title, info, tooltipNote, ...props }: SegmentFieldProps) {
  const textFieldId = `${title}-input`;

  return (
    <Stack gap={0.5} data-testid={`${title}-field`}>
      <Typography variant='labelLarge' component='label' htmlFor={textFieldId}>
        {title}
      </Typography>
      <Typography gutterBottom variant='bodyMedium'>
        {info}
      </Typography>
      <Box sx={{ position: 'relative' }}>
        {tooltipNote && <InfoBox>{tooltipNote}</InfoBox>}
        <TextField
          id={textFieldId}
          data-testid={textFieldId}
          value={props.value || ''}
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
          fullWidth
          multiline
          minRows={3}
          helperText={props.warning}
          warning={(!!props.warning).toString()}
        />
      </Box>
    </Stack>
  );
}
