import {
  capitalize,
  Chip,
  Dialog,
  DialogTitle,
  Divider,
  LinearProgress,
  List,
  ListItem,
  Skeleton,
  Stack,
  SxProps,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  Box,
  SelectChangeEvent,
  CircularProgress,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Locales } from 'components/utils/locales';
import { enqueueSnackbar } from 'notistack';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ErrorOutline } from '@mui/icons-material';
import { KyronEvents } from 'components/utils/KyronEvents';
import { useLessonSettingsDialogContext, useSettingsTitle } from './LessonSettingsDialog';
import { useTTSVoiceOptions } from '../../../controllers/react-query/realtimeTranscriptionHooks';
import { Row } from '../../Row/Row';
import {
  useLessonQuery,
  useUpdateLessonCollection,
  useRegenerateAllLessonVideos,
} from '../../../controllers/react-query';
import { useModal } from '../../utils/ModalContext';
import { KyronSimpleAudioPlayer } from '../../KyronSimpleAudioPlayer/KyronSimpleAudioPlayer';

const voiceListItemStyle: SxProps = {
  justifyContent: 'space-between',
  cursor: 'default',
  padding: '2px 8px',
};

const nonEnglishLocales = Object.entries(Locales).filter(([key]) => key !== 'en');

export function LanguageAndVoiceSettings() {
  useSettingsTitle('Language & Voice');

  const { lessonId } = useParams();
  const { data: lesson, isError: isLessonError, isFetching: isFetchingLesson } = useLessonQuery(Number(lessonId));
  const { data: voiceOptions, isError: isVoiceError, isFetching: isFetchingVoice } = useTTSVoiceOptions();
  const [languages, setLanguages] = useState<string[]>([]);

  const { mutate: regenerateAllLessonVideos, isPending: isRegenerationPending } = useRegenerateAllLessonVideos();
  const { mutate: updateLessonCollection } = useUpdateLessonCollection();

  const { updateLesson } = useLessonSettingsDialogContext();
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    if (lesson?.lesson_collection?.desired_languages) {
      setLanguages(lesson.lesson_collection.desired_languages);
    }
  }, [lesson]);

  const onUpdate = (selectedVoiceId: string) => {
    openModal({
      id: 'confirm-update',
      title: 'Update Voice',
      content: (
        <Typography variant='bodyMedium'>
          You are about to change the voice of the lesson presenter from <b>{lesson?.tts_voice_id}</b> to{' '}
          <b>{selectedVoiceId}</b>. Once you confirm, we will start to <b>regenerate all the videos</b> in this lesson.
          (Excluding the videos uploaded by you)
          <br />
          <br />
          Do you want to proceed?
        </Typography>
      ),
      onConfirm: () => {
        updateLesson(
          { tts_voice_id: selectedVoiceId },
          {
            onSuccessCallback: () => {
              if (lessonId === undefined) {
                return console.error('Lesson ID was undefined when attempting to regenerate videos');
              }

              // Start the regeneration process
              regenerateAllLessonVideos(
                { lessonId, payload: { skip_existing: false } },
                {
                  onSuccess: () => {
                    closeModal(); // close the wait modal that shows the regeneration initiation progress
                    enqueueSnackbar("Started regenerating lesson videos in new voice. It'll take a few minutes.");
                  },
                },
              );
            },
          },
        );
      },
    });
  };

  const voiceLoadingSkeleton = Array.from({ length: 5 }).map((_, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <ListItem key={i} sx={voiceListItemStyle}>
      <Skeleton variant='text' height={28} width={100} />
      <Row>
        <Skeleton variant='rectangular' height={32} width={80} sx={{ mr: 1, borderRadius: 6 }} />
        <Skeleton variant='rectangular' height={32} width={62} sx={{ borderRadius: 6 }} />
      </Row>
    </ListItem>
  ));

  const voiceErrorMessage = (
    <Row justifyContent='center' sx={{ width: '100%' }}>
      <ErrorOutline color='info' sx={{ mr: 1 }} />
      <Typography variant='bodySmall' color='info' sx={{ verticalAlign: 'middle' }}>
        Error occurred while fetching data
      </Typography>
    </Row>
  );

  const handleChangeLanguages = (event: SelectChangeEvent<string[]>) => {
    const newLanguages = event.target.value as string[];
    if (!lesson?.lesson_collection?.id) return console.error('Collection ID is missing in update request');

    KyronEvents.sendEvent(KyronEvents.names.ADD_LANGUAGE, {
      lesson_id: lesson.id,
      languages: newLanguages,
    });
    updateLessonCollection(
      { collectionId: lesson.lesson_collection.id, payload: { desired_languages: newLanguages } },
      {
        onSuccess: () => {
          enqueueSnackbar('Course languages updated successfully', { variant: 'success' });
          setLanguages(newLanguages);
        },
        onError: error => {
          enqueueSnackbar(`Failed to update languages: ${error.message}`, { variant: 'error' });
        },
      },
    );
  };

  const showLoadingItemWhenNecessary = () =>
    !lesson ? (
      <MenuItem value='unknown'>
        {isFetchingLesson ? (
          <LoadingIndicator spinnerSize={20} message='Loading...' data-testid='loading-indicator' />
        ) : (
          '-'
        )}
      </MenuItem>
    ) : null;

  return (
    <Stack spacing={2} sx={{ overflowY: 'scroll', px: 4, pt: 2 }}>
      <Typography variant='bodySmall' color='textSecondary'>
        Change the language and voice settings for your course.
      </Typography>

      <Typography variant='labelMedium' component='label' htmlFor='lesson-language'>
        Language
      </Typography>
      <Typography variant='bodySmall'>Please select the languages for your course to be translated into.</Typography>
      <Row gap={1} sx={{ width: '100%' }}>
        <FormControl sx={{ minWidth: 120, flex: 1 }}>
          <InputLabel id='lesson-language-label' size='small'>
            Language
          </InputLabel>
          <Select
            labelId='lesson-language-label'
            label='Language'
            id='lesson-language'
            size='small'
            multiple
            renderValue={selected => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {selected.map((value: string) => (
                  <Chip key={value} label={nonEnglishLocales.find(([key]) => key === value)?.[1]} />
                ))}
              </Box>
            )}
            value={languages}
            onChange={handleChangeLanguages}
            disabled={isFetchingLesson}
          >
            {showLoadingItemWhenNecessary()}
            {nonEnglishLocales.map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
          {isFetchingLesson && (
            <Row gap={1} sx={{ mt: 1 }}>
              <CircularProgress size={20} />
              <Typography variant='bodySmall' color='textSecondary'>
                Loading...
              </Typography>
            </Row>
          )}
          {isLessonError && <FormHelperText>Error occurred while fetching lesson</FormHelperText>}
        </FormControl>
      </Row>
      <Divider />

      <Typography variant='labelMedium' component='label' htmlFor='lesson-voice'>
        Voice
      </Typography>
      <Typography variant='bodySmall'>Select the voice to be used in your course.</Typography>
      <List
        sx={{
          maxWidth: 400,
          minHeight: 190,
          overflowY: 'auto',
          borderRadius: 2,
          border: t => `1px solid ${t.palette.divider}`,
        }}
      >
        {isFetchingVoice && voiceLoadingSkeleton}
        {isVoiceError && voiceErrorMessage}
        {voiceOptions?.map(voice => {
          const voiceInUse = voice.id === lesson?.tts_voice_id;
          return (
            <ListItem disableGutters onClick={e => e.stopPropagation()} key={voice.id} sx={voiceListItemStyle}>
              {capitalize(voice.label)}
              <Row gap={1}>
                <KyronSimpleAudioPlayer src={voice.sample_url} style={{ marginLeft: '16px' }} />
                <Chip
                  color='primary'
                  variant='outlined'
                  label={voiceInUse ? 'In Use' : 'Apply'}
                  disabled={voiceInUse}
                  onClick={() => onUpdate(voice.id)}
                  sx={{ width: 62 }}
                />
              </Row>
            </ListItem>
          );
        })}
      </List>

      {isRegenerationPending && (
        <Dialog open>
          <DialogTitle>🚧 Starting Regeneration</DialogTitle>
          <Stack alignContent='center' justifyContent='center' px={3} pb={3}>
            <Typography>
              <b>This should only take a few seconds.</b> Please wait while we are starting the regeneration process.
            </Typography>
          </Stack>
          <LinearProgress />
        </Dialog>
      )}
    </Stack>
  );
}
