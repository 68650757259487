import React from 'react';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import { Select, SelectProps, Stack } from '@mui/material';
import { KyronFormFieldLabel } from '../KyronFormFieldLabel';

// Add more ControllerProps in this list to have direct access to them in the HookSelect props
// rather than inside the otherControllerProps prop
type PickedControllerProps = 'name' | 'control' | 'rules';

export type HookSelectProps<T extends FieldValues = FieldValues> = {
  controllerProps?: Omit<ControllerProps<T>, PickedControllerProps | 'render'>;
  topLabel?: string;
  topDescription?: string;
} & SelectProps &
  Pick<ControllerProps<T>, PickedControllerProps>;

/**
 * This is a Select component from MUI v5 wrapped in a Controller component from react-hook-form
 * Ready to use with useForm hook!
 * example usage:
 * ```jsx
 * const { control } = useForm();
 *
 * <HookSelect
 *  name='activityTemplate'
 *  control={control}
 *  rules={{ required: 'This field is required' }}
 *  controllerProps={{ defaultValue: 'exploratory' }}
 *  label='Activity template'
 *  id='activity-template'
 *  fullWidth
 *  />
 * ```
 */
export function HookSelect<T extends FieldValues>({
  name,
  control,
  rules,
  controllerProps,
  topLabel,
  topDescription,
  ...selectProps
}: HookSelectProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      {...controllerProps}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Stack>
          <KyronFormFieldLabel
            label={topLabel}
            description={topDescription}
            htmlFor={selectProps.id}
            required={selectProps.required || Boolean(rules?.required)}
          />
          <Select {...selectProps} onChange={onChange} value={value} error={!!error}>
            {selectProps.children}
          </Select>
        </Stack>
      )}
    />
  );
}
