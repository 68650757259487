import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Input,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import { ArrowForward } from '@mui/icons-material';
import { UserContext } from 'components/UserContext';
import { useGenerateEntireLesson } from 'controllers/react-query';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';
import speed from 'assets/speed.png';
import interactivity from 'assets/interactivity.png';
import analytics from 'assets/analytics.png';
import wgu from 'assets/wgu.png';
import kipp from 'assets/kipp.png';
import baltimore from 'assets/baltimore.png';
import relay from 'assets/relay.png';
import { useGetLessonCollections } from 'controllers/react-query/lessonCollectionHooks';
import { LessonCollection } from 'controllers/types';
import { Helmet } from 'react-helmet';
import { KyronEvents } from 'components/utils/KyronEvents';
import { Newsletter } from 'components/Newsletter/Newsletter';
import { useModal } from 'components/utils/ModalContext';
import { CourseGeneratingMessage } from 'components/Studio/Editor/CourseGeneratingMessage';
import { useExperiments } from 'components/ExperimentsContext';
import { ExamplePrompts } from './components/ExamplePrompts';
import { ValuePropBlock } from './components/ValuePropBlock';
import { CaseStudyCard } from './components/CaseStudyCard';
import { HomepageSection } from './components/HomepageSection';
import { HomepageWrapper } from './components/HomepageWrapper';
import { HomepageHeadline } from './components/HomepageHeadline';
import { CosmicGradient } from './components/CosmicGradient';
import { MarketingHeader } from './components/MarketingHeader';
import { HomepageCourseCard } from './components/HomepageCourseCard';
import { FlowchartStep } from './components/FlowchartStep';
import { FlowchartArrow } from './components/FlowchartArrow';
import { Footer } from './components/Footer';

const valueProps = [
  {
    headline: 'Accelerate Course Development with AI',
    subhead: 'Accelerated Development',
    description:
      'Revolutionize course development by automating the generation of interactive, pedagogically sound videos, dialogues and more. Our intuitive editor helps you achieve significant time and cost savings and enables you to offer anything from short lessons to multi-week courses at a fraction of the time and cost.',
    image: <img src={speed} alt='Accelerated development' width='100%' />,
  },
  {
    headline: 'Truly Interactive Courses',
    subhead: 'Engaging Interactivity',
    description:
      "Create a personalized and guided learning journey anchored by discussions that immediately address learners' misconceptions. Keep learners on track while providing a safe learning environment.",
    image: <img src={interactivity} alt='Engaging interactivity' width='100%' />,
  },
  {
    headline: 'Gain Insight and Drive Efficiency',
    subhead: 'Analytics',
    description:
      'Advanced analytics give you actionable insights on learning trends that enhance instructional strategies and improve learning outcomes, helping you maximize impact. ',
    image: <img src={analytics} alt='Analytics' width='100%' />,
  },
];

const caseStudies = [
  {
    image: <img src={wgu} alt='WGU logo' width={160} />,
    description: 'Dr. Joe Dery, Dean of Data Analytics, Computer Science, and Software Engineering, WGU',
    quote:
      '“In early testing, we’ve seen really positive reactions to the Kyron lessons that we’ve piloted. By providing students with instructor-curated, interactive content on-demand, especially when live human support may not be readily available, we have the potential to really drive stronger course completion rates, which means more students on their way to a timely graduation. It’s really exciting.”',
  },
  {
    image: <img src={kipp} alt='KIPP logo' width={160} />,
    description: 'April Goble, Executive Director, KIPP Chicago Schools',
    quote:
      '“The Kyron Studio beta puts the power of AI-assisted instructional content creation in the hands of our teachers. The fact that the teacher is able to review and edit how the AI interacts with our students is important, and it helps teachers feel more confident about using AI in the classroom.”',
  },
  {
    image: <img src={baltimore} alt='Baltimore City Public Schools logo' width={160} />,
    description: 'John Davis, Chief of Schools, Baltimore City Public Schools',
    quote:
      '“Kyron is an excellent partner to provide math resources for teachers and students. Their lessons utilize excellent teachers aligned with state standards in an AI environment. They consistently want and act on feedback, strive to make their lessons teacher and student friendly, and are open and thoughtful with how AI is being implemented in a classroom environment.”',
    path: '/case-study',
  },
  {
    image: <img src={relay} alt='Relay GSE logo' width={160} />,
    description: 'Mayme Hostetter, President, Relay GSE',
    quote:
      '“Kyron has been a great partner in building virtual experiences for our students. Their team is innovative, considerate and invested in making improvements that matter for the entire learning journey.”',
    path: '/case-study',
  },
];

const flowchartSteps = [
  {
    number: 1,
    title: 'Generate',
    subtitle:
      'Generate courses that include video lectures, interactive discussions and quizzes. Upload any existing materials you have.',
  },
  {
    number: 2,
    title: 'Customize',
    subtitle:
      'Use our easy editing tools to customize the generated course activities. Add your own branding and make it yours.',
  },
  {
    number: 3,
    title: 'Deploy',
    subtitle:
      'Deploy the course in your LMS with SCORM or LTI, or share with a simple link. Get actionable learner reports.',
  },
];

export function MarketingHome() {
  const { hash } = useLocation();
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const { openModal, closeModal } = useModal();

  const [titlePrompt, setTitlePrompt] = useState('');
  const { user } = useContext(UserContext);
  const { isPending, mutate: createLesson } = useGenerateEntireLesson();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { data: lessonCollections } = useGetLessonCollections({ featured: true });
  const previewCourse = lessonCollections?.[0];
  const { test_experiment: testExperiment } = useExperiments();

  useEffect(scrollToAnchor, [hash]);
  function scrollToAnchor() {
    if (hash) document.querySelector(hash)?.scrollIntoView();
  }

  const courseFromTitlePayload = {
    lessonTitle: titlePrompt,
    lessonObjective: titlePrompt, // Send the title as the objectives, which will get refined through the structured learning objectives process
    audience: 'College Students',
  };

  function handleCreateLesson() {
    if (user) {
      KyronEvents.sendEvent(KyronEvents.names.CREATE_COURSE_FROM_TITLE);
      createLesson(
        { payload: courseFromTitlePayload },
        {
          onSuccess: lesson => {
            navigate(`/studio/courses/${lesson.id}`);
            openModal({
              id: 'course-creating',
              title: 'Your course is being created!',
              content: <CourseGeneratingMessage title={lesson.name} />,
              action: <Button onClick={closeModal}>Got it</Button>,
            });
          },
          onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
            console.error(error.message);
          },
        },
      );
    } else {
      window.open(`/login?course_title=${encodeURIComponent(titlePrompt)}`, '_blank');
    }
  }

  function sampleCourse(course: LessonCollection) {
    return (
      <>
        <Typography variant='displaySmall' component='p' sx={{ textAlign: 'center' }}>
          Preview a Kyron course
        </Typography>
        <Stack mt={2} sx={{ position: 'relative', zIndex: 1, px: 3 }}>
          <Box
            sx={{
              width: 'calc(100% + 96px)',
              marginLeft: '-48px',
            }}
          >
            <Box maxWidth='960px' sx={{ m: '0 auto' }}>
              <HomepageCourseCard course={course} />
            </Box>
          </Box>
        </Stack>
      </>
    );
  }

  function createDemoCourse() {
    const headlineText = `Generate 3 courses for free.\n What do you want to teach today?`;
    return (
      <HomepageWrapper maxWidth='1232px' gap='64px'>
        <Typography variant='displayMedium' component='h2' textAlign='center' whiteSpace='pre-line'>
          {headlineText}
        </Typography>

        <Stack gap={3}>
          <Input
            type='text'
            name='learning_objective'
            placeholder='Enter the title of your course here'
            value={titlePrompt}
            onChange={e => setTitlePrompt(e.target.value)}
            disableUnderline
            data-testid='course-title-input'
            sx={{
              height: '72px',
              fontSize: '20px',
              borderRadius: 3,
              bgcolor: '#FFFFFF',
              border: 'none',
              px: { sm: 3, xs: 2 },
              gap: 1,
              boxShadow:
                '0px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34), 0px 1.1px 1.8px -0.8px rgba(162, 147, 169, 0.34), 0px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34), 0px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34)',
            }}
            endAdornment={
              <IconButton
                aria-label='Create course'
                color='primary'
                variant='filled'
                disabled={titlePrompt === ''}
                onClick={handleCreateLesson}
                data-testid='create-course-from-title'
              >
                {isPending ? <CircularProgress size={24} color='inherit' /> : <ArrowForward />}
              </IconButton>
            }
          />
          <ExamplePrompts onPromptClick={prompt => setTitlePrompt(prompt.lessonTitle || '')} dense={false} />
        </Stack>
      </HomepageWrapper>
    );
  }

  return (
    <>
      <Helmet>
        <link rel='canonical' href='https://www.kyronlearning.com/' />
      </Helmet>
      <MarketingHeader />

      <Newsletter />
      <HomepageSection pt={isMobile ? '72px' : '90px'} bgcolor='#FFF5EC'>
        <HomepageWrapper gap={isMobile ? '72px' : '90px'}>
          <Stack gap='36px' maxWidth='720px' alignItems='center' margin='0 auto'>
            <HomepageHeadline>Create interactive courses in minutes</HomepageHeadline>
            {/* <Typography
              variant='displayMedium'
              component='h2'
              sx={{
                fontSize: { sm: '40px', xs: '32px' },
                lineHeight: { sm: '48px', xs: '40px' },
                fontWeight: 'normal',
                maxWidth: '680px',
              }}
            >
              Build anything from a 10-minute lesson to an 8-week course
            </Typography> */}
            <Typography
              variant='bodyLarge'
              textAlign='center'
              maxWidth='680px'
              sx={{
                fontSize: { sm: '24px', xs: '19px' },
                lineHeight: { sm: '32px', xs: '27px' },
                color: 'text.secondary',
              }}
            >
              Harness AI to generate video lectures, quizzes and interactive discussion activities fast.
            </Typography>
          </Stack>

          <Stack
            justifyContent='space-between'
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems='top'
            gap={8}
            position='relative'
          >
            {flowchartSteps.map(({ number, title, subtitle }) => (
              <FlowchartStep key={title} number={number} title={title} subtitle={subtitle} />
            ))}

            {/* Place the two arrows between the steps -- one about a third of the way into the stack,
            and the other about two-thirds of the way into the stack */}
            <FlowchartArrow top='5%' left='35%' isMobile={isMobile} scaleY={1} />
            <FlowchartArrow top='15%' left='69%' isMobile={isMobile} scaleY={-1} />
          </Stack>
        </HomepageWrapper>

        <Box mt='120px'>{createDemoCourse()}</Box>

        <CosmicGradient sx={{ position: 'absolute', left: 'calc(50% + 145px)', top: '159px', zIndex: 0 }} />
        <div style={{ display: 'none' }} id='experiment-metadata'>
          {testExperiment}
        </div>
      </HomepageSection>

      {previewCourse && (
        <HomepageSection
          pt={isMobile ? '80px' : '130px'}
          pb={isMobile ? '80px' : '130px'}
          bgcolor='#FAFCFF'
          id='sample-course'
        >
          {sampleCourse(previewCourse)}

          <CosmicGradient
            variant='nebula'
            sx={{ position: 'absolute', left: 'calc(50% - 410px)', bottom: '-320px', zIndex: 0 }}
          />
        </HomepageSection>
      )}

      <HomepageSection
        pt={isMobile ? '80px' : '160px'}
        pb={isMobile ? '80px' : '160px'}
        bgcolor='#FAF7F9'
        id='benefits'
      >
        <HomepageWrapper gap={isMobile ? '64px' : '130px'}>
          <HomepageHeadline subhead='Key benefits of online course creation'>Why use Kyron?</HomepageHeadline>

          {valueProps.map(({ headline, subhead, description, image }) => (
            <ValuePropBlock headline={headline} subhead={subhead} image={image} key={headline}>
              {description}
            </ValuePropBlock>
          ))}
        </HomepageWrapper>
        <CosmicGradient
          variant='forest'
          sx={{ position: 'absolute', left: 'calc(50% - 1000px)', top: '-100px', zIndex: 0 }}
        />
        <CosmicGradient
          variant='forest'
          sx={{ position: 'absolute', left: 'calc(50% + 300px)', bottom: '-100px', zIndex: 0 }}
        />
      </HomepageSection>

      <HomepageSection
        pt={isMobile ? '80px' : '130px'}
        pb={isMobile ? '80px' : '130px'}
        bgcolor='#03317B'
        id='customers'
      >
        <HomepageWrapper gap='90px'>
          <HomepageHeadline color='#E5E1E6' subhead='Customers'>
            Don’t take our word for it
          </HomepageHeadline>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                sm: 'repeat(auto-fill, minmax(480px, 1fr))',
                xs: 'repeat(auto-fill, minmax(100%, 1fr))',
              },
              gap: '32px',
            }}
          >
            {caseStudies.map(({ description, quote, image }) => (
              <CaseStudyCard description={description} quote={quote} image={image} key={quote} />
            ))}
          </Box>
        </HomepageWrapper>

        <CosmicGradient
          variant='nebula'
          sx={{ position: 'absolute', left: 'calc(50% - 410px)', top: '-400px', zIndex: 0 }}
        />
      </HomepageSection>

      <Footer />
    </>
  );
}
