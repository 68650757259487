import { QuestionMark } from '@mui/icons-material';
import { Divider, MenuItem, SxProps } from '@mui/material';
import { KyronMenu } from 'components/KyronMenu';
import React from 'react';
import { useIntercom } from 'react-use-intercom';

type HelpButtonProps = {
  sx?: SxProps;
};

export function HelpButton({ sx }: HelpButtonProps) {
  const { showSpace } = useIntercom();

  return (
    <KyronMenu
      buttonSx={{
        boxShadow: 2,
        bgcolor: 'surface.main',
        color: 'text.primary',
        position: 'fixed',
        bottom: '24px',
        right: '24px',
        ...sx,
      }}
      menuIcon={<QuestionMark />}
    >
      <MenuItem disabled>What&apos;s new</MenuItem>
      <Divider />
      <MenuItem onClick={() => showSpace('home')}>Help center</MenuItem>
      <MenuItem onClick={() => showSpace('messages')}>Contact support</MenuItem>
      <MenuItem disabled>Share feedback</MenuItem>
    </KyronMenu>
  );
}
