import React from 'react';
import { CourseCreationLayout } from 'components/Studio/Create/Components/CourseCreationLayout';
import { CourseBuilder } from 'components/Studio/Create/Forms/CourseBuilder';
import { PracticeProblemsBuilder } from 'components/Studio/Create/Forms/PracticeProblemsBuilder';
import { LessonBuilder } from 'components/Studio/Create/LessonBuilder';
import { Navigate, RouteObject } from 'react-router-dom';

export const courseBuilderRoutes: RouteObject[] = [
  {
    path: 'lesson-builder',
    element: <Navigate to='/create' replace />,
  },
  {
    path: 'create',
    element: <CourseCreationLayout />,
    children: [
      {
        index: true,
        element: <LessonBuilder />,
      },
      {
        path: 'practice',
        element: <PracticeProblemsBuilder />,
      },
      {
        path: 'course/:lessonId',
        element: <CourseBuilder />,
      },
    ],
  },
];
