import React from 'react';
import { Typography } from '@mui/material';

type Props = {
  label?: string;
  description?: string;
  htmlFor?: string;
  required?: boolean;
};

export function KyronFormFieldLabel({ label, description, htmlFor, required }: Props) {
  return (
    <>
      {label && (
        <Typography variant='labelLarge' component='label' htmlFor={htmlFor}>
          {label}
          {required ? ' *' : ''}
        </Typography>
      )}
      {description && (
        <Typography variant='bodyMedium' color='text.secondary' mb={0.5}>
          {description}
        </Typography>
      )}
    </>
  );
}
