import React, { useMemo } from 'react';
import { sampleSize } from 'lodash';
import { Row } from 'components/Row/Row';
import { BasicLessonPayload } from 'controllers/types';
import { SAMPLE_COURSES } from '../titles';
import { useExamplePromptsQuery } from '../../../controllers/react-query/feelingLuckyHooks';
import { ExamplePromptButton } from './ExamplePromptButton';

export function useTitlePrompts(
  count: number,
  learningEnvironment?: string,
): { prompts: BasicLessonPayload[]; isFetching: boolean } {
  const shouldFetchFromBackend = Boolean(learningEnvironment);

  // Fetch titles from the backend if learningEnvironment is provided
  const { data: backendPrompts, isFetching } = useExamplePromptsQuery(
    count,
    shouldFetchFromBackend,
    learningEnvironment,
  );

  // If backend titles are available and not currently fetching, use them
  const prompts = useMemo(() => {
    if (!isFetching && backendPrompts?.length) {
      return backendPrompts;
    }

    // Use random titles from the sample array if backend call is disabled or fails
    return sampleSize(SAMPLE_COURSES, count);
  }, [count, backendPrompts, isFetching]);

  return { prompts, isFetching };
}

type ExamplePromptsProps = {
  onPromptClick: (prompt: BasicLessonPayload) => void;
  dense: boolean;
};

export function ExamplePrompts({ onPromptClick, dense = false }: ExamplePromptsProps) {
  // useTitlePrompts can optionally take in a learning environment to generate titles
  // from the backend. This is useful for showing relevant prompts based on the user's context.
  const { prompts: examplePrompts, isFetching } = useTitlePrompts(3);

  return (
    <Row
      sx={{
        gap: { sm: 3, xs: 0 },
        alignItems: 'stretch',
        mx: { sm: '0', xs: '-16px' },
        '&::-webkit-scrollbar': { display: 'none' },
      }}
    >
      {examplePrompts.map((prompt, index) => (
        <ExamplePromptButton
          key={prompt.lessonTitle}
          loading={isFetching}
          prompt={prompt}
          id={index.toString()}
          onClick={onPromptClick}
          dense={dense}
          sx={{
            flex: { sm: 1, xs: '0 0 75%' },
            mr: { sm: '0', xs: '16px' },
            ':first-of-type': { ml: { sm: '0', xs: '16px' } },
          }}
        />
      ))}
    </Row>
  );
}
