import { courseLengths } from './CourseOverviewForm';

export const defaultValues = {
  courseOverview: {
    name: '',
    intended_time: courseLengths[1].value,
    audience: '',
    objective: '',
    description: '',
  },
};

export type FormValues = typeof defaultValues;
