import { redirect } from 'react-router-dom';
import { Lesson, LessonCollection, LessonContainer, LessonPayload, MutationRespWithBT } from 'controllers/types';
import { useSnackbar } from 'notistack';
import { queryFn, useKyronQuery } from './kyronQuery';
import { queryClient } from './config/queryClient';
import { getSearchParamStr } from '../../components/utils/urlUtils';
import { useKyronMutationV2 } from './kyronMutation';

export const useLessonContainerQuery = (id?: number | null, disable?: boolean) =>
  useKyronQuery<LessonContainer>(`/lesson_containers/${id}`, { enabled: !!id && !disable });

export const useLessonCollectionQuery = (id?: number | null, disable?: boolean) =>
  useKyronQuery<LessonCollection>(`/lesson_collections/${id}`, { enabled: !!id && !disable });

export const useLessonContainerCollectionQuery = (
  lessonContainerId: number,
  lessonCollectionId: number,
  disable?: boolean,
) => {
  const {
    data: lessonContainer,
    isLoading: isContainerLoading,
    isError: isContainerError,
    error: containerError,
  } = useLessonContainerQuery(lessonContainerId, disable);
  const {
    data: lessonCollection,
    isLoading: isCollectionLoading,
    isError: isCollectionError,
    error: collectionError,
  } = useLessonCollectionQuery(lessonCollectionId, disable);
  const isLoading = isContainerLoading || isCollectionLoading;
  const isError = isContainerError && isCollectionError;

  if (isError) {
    console.error('Error fetching lesson container and lesson collection', containerError, collectionError);
  }

  return {
    data: lessonCollectionId ? lessonCollection : lessonContainer,
    isLoading,
    isError,
  };
};
/**
 * **Redirects to the video player if the lesson collection has only one lesson**
 *
 * The catch in this function is that it intentionally uses queryClient.fetchQuery
 * to cache the received lesson container data. This will help avoiding an extra API call
 * if there are multiple lessons in the lesson container and we are are rendering the catalog.
 * @param lessonCollectionId
 */
export const redirectToVideoIfSingleLesson = async (lessonCollectionId: string, prefix: string) => {
  console.warn('Auto redirection by redirectToVideoIfSingleLesson for lessonCollectionId:', lessonCollectionId);
  const search = new URLSearchParams(window.location.search);
  // we wanna relay the search params to the request there it might have some important data for authentication.
  // e.g. lti_launch
  const apiPath = `/${prefix}s/${lessonCollectionId}${getSearchParamStr(search)}`;
  try {
    // using queryClient.fetchQuery to keep data in cache for any subsequent calls to the same endpoint
    const lessonCollection = await queryClient.fetchQuery({
      queryKey: [apiPath],
      queryFn: queryFn<LessonCollection | LessonContainer>(apiPath, true), // the common queryFn used by useKyronQuery as well
    });
    // if only one lessons in the container, redirect to the video player
    if (lessonCollection?.lessons?.length === 1) {
      const url = `/video_player/${lessonCollection.lessons[0].id}`;

      if (search.get('google_classroom_id')) {
        // this is used in GoogleClassroomStudentValidator and should only be added in search params
        // if google_classroom_id is present in search params as well. Otherwise, trouble...
        search.set(`${prefix}_id`, `${lessonCollection.id}`);
      }

      const urlSearch = `${getSearchParamStr(search)}`; // relay previous search params when redirecting as they needed'
      return redirect(`${url}${urlSearch}`);
    }
  } catch (e) {
    console.error(`Failed to fetch lesson container ${lessonCollectionId}`);
    return { error: (e as Error).message };
  }
  return null;
};

export const redirectOldUrlToVideoIfSingleLesson = async (lessonContainerId: string) =>
  redirectToVideoIfSingleLesson(lessonContainerId, 'lesson_container');

export const redirectNewUrlToVideoIfSingleLesson = async (lessonCollectionId: string) =>
  redirectToVideoIfSingleLesson(lessonCollectionId, 'lesson_collection');

export function useUpdateLessonAndLessonCollection({
  lessonId,
  collectionId,
}: {
  lessonId?: number;
  collectionId?: number;
}) {
  const {
    mutate: updateLesson,
    mutateAsync: updateLessonAsync,
    isPending: isUpdateLessonPending,
  } = useKyronMutationV2<{ payload: Partial<LessonPayload> }, MutationRespWithBT<Lesson>>(`/lessons/${lessonId}`, {
    method: 'PUT',
    invalidatesMultiple: [[`/lessons/${lessonId}`], [`/lessons`], [`/lessons/${lessonId}/lesson_sections`]],
  });
  const {
    mutate: updateLessonCollection,
    mutateAsync: updateLessonCollectionAsync,
    isPending: isUpdateLessonCollectionPending,
  } = useKyronMutationV2<{ payload: Partial<LessonCollection> }, MutationRespWithBT<LessonCollection>>(
    `/lesson_collections/${collectionId}`,
    {
      method: 'PUT',
      invalidatesMultiple: [[`/lesson_collections/${collectionId}`], [`/lesson_collections`], [`/lessons/${lessonId}`]],
    },
  );

  const { enqueueSnackbar } = useSnackbar();

  const errorTitle = 'Error updating lesson';

  function mutate(payload: Partial<LessonPayload>, { onSuccessCallback }: { onSuccessCallback?: () => void } = {}) {
    updateLesson(
      { payload },
      {
        onSuccess: () => {
          updateLessonCollection(
            {
              payload: {
                name: payload.name,
                description: payload.description,
                custom_thumbnail: payload.lesson_collection?.custom_thumbnail,
              },
            },
            {
              onSuccess: onSuccessCallback,
              onError: error => {
                console.error(`${errorTitle}: ${error.message}`);
                enqueueSnackbar(errorTitle, { variant: 'error' });
              },
            },
          );
        },
        onError: error => {
          console.error(`${errorTitle}: ${error.message}`);
          enqueueSnackbar(errorTitle, { variant: 'error' });
        },
      },
    );
  }

  async function mutateAsync(payload: Partial<LessonPayload>) {
    try {
      await updateLessonAsync({ payload });
      await updateLessonCollectionAsync({
        payload: {
          name: payload.name,
          description: payload.description,
          custom_thumbnail: payload.lesson_collection?.custom_thumbnail,
        },
      });
    } catch (error) {
      console.error(`${errorTitle}: ${error}`);
      enqueueSnackbar(errorTitle, { variant: 'error' });
    }
  }

  return { mutate, mutateAsync, isPending: isUpdateLessonPending || isUpdateLessonCollectionPending };
}
