import { createContext, useContext } from 'react';
import { MultiStepFormStep } from './types';
import { KyronID } from '../../../../../controllers/types';

export type MultiStepFormContextState = { activeStep: number; collapsedModules: KyronID[] };
export type TMultiStepFormContext = {
  steps: MultiStepFormStep[];
  activeStep: number;
  goToPreviousStep: () => void;
  goToNextStep: () => void;
  contextState: MultiStepFormContextState;
  setContextState: (
    s: Partial<MultiStepFormContextState> | ((p: MultiStepFormContextState) => Partial<MultiStepFormContextState>),
  ) => void;
  config: { MAX_WIDTH: number };
  lessonId: string | undefined;
};

export const MultiStepFormContext = createContext<TMultiStepFormContext | null>(null);
export const useMultiStepFormContext = () => {
  const ctx = useContext(MultiStepFormContext);
  if (!ctx) throw new Error('useMultiStepFormContext must be used within a MultiStepFormContext');
  return ctx;
};
