import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  MenuItem,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { useModal } from 'components/utils/ModalContext';
import { KyronEvents } from 'components/utils/KyronEvents';
import { LessonSection } from 'controllers/types';
import { useUpdateSection, useDeleteSection } from 'controllers/react-query';
import { useSnackbar } from 'notistack';
import { KyronMenu } from '../../KyronMenu';
import { SegmentList } from './SegmentList';
import { AddSegmentDialog } from './AddToLesson/AddSegmentDialog';
import { useEditorContext } from './EditorContext';

const RenameSectionModalContent = ({ topic, section }: { topic: string; section: LessonSection }) => {
  const [newTopic, setNewTopic] = useState(topic);
  const { closeModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: updateSectionTopic, isPending } = useUpdateSection();
  const renameSection = () => {
    updateSectionTopic(
      {
        lessonSectionId: section.id,
        lessonId: section.lesson_id,
        payload: { lessonSection: { topic: newTopic } },
      },
      {
        onSuccess: closeModal,
        onError: error => {
          const errorTitle = `Failed to update topic`;
          console.error(errorTitle, section.id, error.message);
          enqueueSnackbar(errorTitle, { variant: 'error' });
          closeModal();
        },
      },
    );
  };

  const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') {
      return;
    }
    renameSection();
  };

  return (
    <Stack>
      <TextField
        autoFocus
        data-testid='rename-section-input'
        fullWidth
        label={`Change topic from ${topic}`}
        onChange={e => setNewTopic(e.target.value)}
        required
        onKeyDown={handleEnterKey}
        sx={{ marginTop: 2 }}
        value={newTopic}
      />
      <DialogActions sx={{ paddingRight: 0 }}>
        <Button variant='text' onClick={closeModal} data-testid='ai-feedback-cancel-button'>
          Cancel
        </Button>
        <Button
          onClick={renameSection}
          data-testid='update-topic-submit-button'
          disabled={!newTopic.length || isPending}
        >
          {isPending ? <CircularProgress size={20} /> : 'Save'}
        </Button>
      </DialogActions>
    </Stack>
  );
};

type SectionListItemProps = {
  isLast: boolean;
  reposition: (section: LessonSection, params: { direction: number }) => void;
  section: LessonSection;
};

export function SectionListItem({ isLast, reposition, section }: SectionListItemProps) {
  const { goToFirstSegment, editorState } = useEditorContext();
  const [inFocus, setInFocus] = useState(false);
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const { closeModal, openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: deleteSection } = useDeleteSection();
  const repositionDisabled = (position: number, direction: number) => {
    if (direction === -1 && position === 1) return true;
    if (direction === 1 && isLast) return true;
    return false;
  };

  function openDeleteModal() {
    const { activeSegmentId } = editorState;
    if (!activeSegmentId) return;

    const handleDelete = () =>
      deleteSection({ lessonSectionId: section.id, lessonId: section.lesson_id, activeSegmentId })
        .then(resp => {
          KyronEvents.sendEvent(KyronEvents.names.DELETE_SECTION, {
            section_id: section.id,
          });
          goToFirstSegment(resp.lesson_sections);
        })
        .catch(error => {
          const errorTitle = `Failed to delete section`;
          console.error(errorTitle, section.id, error.message);
          enqueueSnackbar(errorTitle, { variant: 'error' });
        });

    openModal({
      id: 'delete-section',
      title: 'Delete Section',
      content: 'Are you sure you want to delete this section?',
      onConfirm: handleDelete,
    });
  }

  const editSectionMenu = (visible: boolean) => (
    <Box data-testid='EditSectionMenu' sx={{ opacity: `${visible ? '1' : '0'}` }}>
      <KyronMenu menuIcon={<MoreVert />}>
        <MenuItem
          onClick={() =>
            openModal({
              id: 'add-segment-modal',
              title: 'Add Segment',
              content: <AddSegmentDialog section={section} onClose={closeModal} />,
            })
          }
        >
          Add Segment
        </MenuItem>
        <MenuItem
          onClick={() =>
            openModal({
              id: `rename-section-${section.id}`,
              title: 'Rename Section',
              content: <RenameSectionModalContent topic={section.topic} section={section} />,
              fullWidth: true,
              dismissible: false,
            })
          }
        >
          Rename Section
        </MenuItem>
        <MenuItem
          onClick={() => reposition(section, { direction: -1 })}
          disabled={repositionDisabled(section.position, -1)}
        >
          Move Section Up
        </MenuItem>
        <MenuItem
          onClick={() => reposition(section, { direction: 1 })}
          disabled={repositionDisabled(section.position, 1)}
        >
          Move Section Down
        </MenuItem>
        <MenuItem onClick={openDeleteModal} sx={{ color: 'red' }}>
          Delete Section
        </MenuItem>
      </KyronMenu>
    </Box>
  );

  return (
    <Box
      onMouseOver={() => setInFocus(true)}
      onMouseOut={() => setInFocus(false)}
      onFocus={() => setInFocus(true)}
      onBlur={() => setInFocus(false)}
      position='relative'
      sx={{ position: 'relative', marginLeft: '-44px', paddingLeft: '44px' }}
    >
      <Box sx={{ position: 'absolute', top: '-7px', left: '0' }}>{editSectionMenu(inFocus || isMobile)}</Box>

      <Typography variant='titleSmall' data-testid='section-topic'>
        {section.topic}
      </Typography>

      <SegmentList section={section} />
    </Box>
  );
}
