import React, { useCallback, useMemo, useState } from 'react';
import { useForm, FormProvider, FieldValues, DefaultValues, UseFormReturn } from 'react-hook-form';
import { Step, StepLabel, Stepper, Paper, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { MultiStepFormStep } from './types';
import { TMultiStepFormContext, MultiStepFormContext, MultiStepFormContextState } from './context';

const MAX_WIDTH = 880;

const MultiStepFormPaper = styled(Paper)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  mx: 'auto',
}));

export type Props<TValues extends FieldValues> = {
  steps: MultiStepFormStep[];
  defaultValues: DefaultValues<TValues>;
  currentStep?: number;
  // testFormCtx is used to pass in the form context from tests to test this component
  $$testFormCtx?: UseFormReturn<TValues>;
};

// MultiStepForm manages the global form state and navigation within given forms (steps)
// In combination with the MultiStepFormFooter, forms can manage navigation and state
export function MultiStepForm<TValues extends FieldValues>({
  steps,
  defaultValues,
  currentStep = 0,
  $$testFormCtx,
}: Props<TValues>) {
  const { lessonId } = useParams();
  const [$contextState, $setContextState] = useState<MultiStepFormContextState>({
    activeStep: currentStep,
    collapsedModules: [],
  });

  const methods = useForm<TValues>({ defaultValues });
  const formProps = $$testFormCtx || methods; // we use test context when rendering in tests
  const { formState } = formProps;

  const setContextState = useCallback<TMultiStepFormContext['setContextState']>(newState => {
    if (typeof newState === 'function') {
      $setContextState(p => ({ ...p, ...newState(p) }));
    }
    $setContextState(p => ({ ...p, ...newState }));
  }, []);

  const goToNextStep = useCallback(() => {
    // Prevent progressing to the next step if the form values are invalid
    if (!formState.isValid) {
      // Form will show errors automatically on submit
      return;
    }
    setContextState(prevStep => ({ activeStep: prevStep.activeStep + 1 }));
  }, [setContextState, formState.isValid]);

  const goToPreviousStep = useCallback(() => {
    setContextState(prevStep => ({ activeStep: prevStep.activeStep - 1 }));
  }, [setContextState]);

  const ctx = useMemo(
    () => ({
      steps,
      activeStep: $contextState.activeStep,
      goToPreviousStep,
      goToNextStep,
      contextState: $contextState,
      setContextState,
      config: { MAX_WIDTH },
      lessonId,
    }),
    [steps, goToPreviousStep, goToNextStep, $contextState, setContextState, lessonId],
  );

  return (
    <FormProvider {...formProps}>
      <MultiStepFormContext.Provider value={ctx}>
        <MultiStepFormPaper>
          <Stack width={MAX_WIDTH} alignItems='center'>
            <Stepper
              data-testid='multistep-form-stepper'
              activeStep={$contextState.activeStep}
              sx={{ mb: 4, width: '25%', alignSelf: 'center' }}
              alternativeLabel
            >
              {steps.map(step => (
                <Step key={step.id}>
                  <StepLabel />
                </Step>
              ))}
            </Stepper>

            <Stack sx={{ mt: 4 }} alignItems='center'>
              <form>{steps[$contextState.activeStep]?.form}</form>
            </Stack>
          </Stack>
        </MultiStepFormPaper>
      </MultiStepFormContext.Provider>
    </FormProvider>
  );
}
