import React from 'react';
import { Tabs, Tab, Stack, Button, Theme, useMediaQuery } from '@mui/material';
import { Add, CollectionsBookmarkOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useInitLesson } from 'controllers/react-query/lessonHooks';
import { Link, Outlet, useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import { useActions, useTitle } from 'components/StudioLayout/StudioLayout';
import { useFeatures } from 'components/FeaturesContext';
import { KyronMenu } from 'components/KyronMenu';
import { KyronMenuItem } from 'components/KyronMenuItem';
import { KyronEvents } from 'components/utils/KyronEvents';

const title = (
  <>
    <CollectionsBookmarkOutlined /> Courses
  </>
);

type HeaderContextType = {
  setTitle: (title: string) => void;
  setActions: (actions: React.ReactNode) => void;
};

export function LibraryLayout() {
  const { pathname } = useLocation();
  const pathRoot = '/studio';
  const { setTitle, setActions } = useOutletContext<HeaderContextType>();
  const { showPlaylist } = useFeatures();

  if (showPlaylist) {
    return (
      <Stack gap={4} mt={3}>
        <Tabs value={pathname}>
          <Tab label='Courses' to='courses' value={`${pathRoot}/courses`} component={Link} replace />
          <Tab
            label='Playlists'
            to='playlists'
            value={`${pathRoot}/playlists`}
            component={Link}
            replace
            disabled={!showPlaylist}
          />
        </Tabs>
        <Outlet context={{ setTitle, setActions }} />
      </Stack>
    );
  }

  return <Outlet context={{ setTitle, setActions }} />;
}

export const useHeader = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { show_playlist: showPlaylist, incremental_course_creation: incrementalCourseCreation } = useFeatures();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: initNewLesson, isPending } = useInitLesson();

  const navigate = useNavigate();

  const handleCreateCourse = () => {
    KyronEvents.sendEvent(KyronEvents.names.OPEN_COURSE_CREATOR);

    if (incrementalCourseCreation) {
      initNewLesson({})
        .then(lesson => {
          navigate(`/create/course/${lesson.id}`);
        })
        .catch(error => {
          enqueueSnackbar(`Something went wrong: ${error.message}`, { variant: 'error' });
        });
    } else {
      navigate('/create');
    }
  };

  useTitle(isMobile ? null : title);
  useActions(
    showPlaylist ? (
      <KyronMenu menuIcon={<Button startIcon={<Add />}>Create</Button>}>
        <KyronMenuItem label='New Course' onClick={handleCreateCourse} disabled={isPending} />
        {showPlaylist && <KyronMenuItem label='New Playlist' component={Link} to='/studio/playlists/new' />}
      </KyronMenu>
    ) : (
      <Button startIcon={<Add />} onClick={handleCreateCourse} disabled={isPending}>
        Create new course
      </Button>
    ),
  );
};
