import React from 'react';
import { Box, Typography } from '@mui/material';
import { ErrorOutline, InfoOutlined } from '@mui/icons-material';
import { LoadingIndicator } from '../../../../LoadingIndicator';
import { Row } from '../../../../Row/Row';

export type Props = {
  variant: 'progress' | 'error' | 'info';
  message: string;
};

export function FormContentPlaceholder({ variant, message }: Props) {
  return (
    <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
      {variant === 'progress' ? <LoadingIndicator message={message} textVariant='bodyMedium' /> : null}

      {variant === 'error' ? (
        <Row gap={2} sx={{ color: t => t.palette.error.main }}>
          <ErrorOutline />
          <Typography component='span' variant='bodyMedium'>
            <b>Error: </b>
            {message}
          </Typography>
        </Row>
      ) : null}

      {variant === 'info' ? (
        <Row gap={2} sx={{ color: t => t.palette.info.main }}>
          <InfoOutlined />
          <Typography component='span' variant='bodyMedium'>
            {message}
          </Typography>
        </Row>
      ) : null}
    </Box>
  );
}
