import React, { useContext } from 'react';

export const ExperimentsContext = React.createContext<Experiments>({});

export type Experiments = Record<string, string>;

type ExperimentProviderProps = {
  children: React.ReactNode;
  experiments: Experiments;
};
export const ExperimentsProvider = ({ children, experiments }: ExperimentProviderProps) => {
  // All of our experiments are defined in snake_case because we define them in Ruby which uses snake_case. However,
  // all of our TypeScript code would prefer that they were in camelCase so we expose them in both formats on the
  // frontend.
  Object.keys(experiments).forEach((experiment: string) => {
    const camelCaseKey = experiment.replace(/_(\w)/g, (_, letter) => letter.toUpperCase());
    experiments[camelCaseKey] = experiments[experiment];
  });
  return <ExperimentsContext.Provider value={experiments}>{children}</ExperimentsContext.Provider>;
};

export const useExperiments = <T extends Experiments>(): T => {
  const experiments = useContext(ExperimentsContext);
  return experiments as T;
};
